import React from 'react'
import QuizItem from './QuizItem';
import { QuizType } from '../../../service/types';

interface Props {
    data: QuizType[];
    isCampaign?: boolean
};

const QuizList: React.FC<Props> = ({ data, isCampaign = false }) => {
    return (
        <div style={{display: 'flex', flexWrap: 'wrap', alignItems: isCampaign ? 'flex-start' : undefined, margin: '0px 20px', height: !isCampaign ? 'calc(100vh - 200px)': '90vh', overflowY: 'auto'}}>
            {
                data?.map((item: any, key: number) => (
                    <QuizItem item={item} key={key} isCampaign={isCampaign} />
                ))
            }
        </div>
    )
}

export default QuizList