import React, { useState, useCallback, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert"
import { useNavigate } from "react-router-dom";

import List from "./List";
import GetUsers from "../../graphql/queries/GetUsers";
import EnableOrDisableUser from "../../graphql/mutations/EnableOrDisableUser";
import TableActionCell from "./TableActionCell";
import { notification } from "../../service";
import AddButton from "../common/AddButton";
import type { RootState } from "../../reducers/store";
import type { Direction } from "../../service/types";
import CustomAlert from "../common/CustomAlert";


const UsersList: React.FC = () => {
  const [order, setOrder] = useState<String>("firstName");
  const [search, setSearch] = useState<String>('');
  const [dir, setDir] = useState<Direction>('asc');
  const [show, setShow] = useState<boolean>(false);
  const { theme } = useSelector((state: RootState) => state.sidebar)
  const { isSystem } = useSelector((state: RootState) => state.app)
  const [enableOrDisableUser] = useMutation(EnableOrDisableUser);
  const navigate = useNavigate()

  const handleSortingChange = useCallback((columnDir: String, orderBy: String) => {
    const isDesc = columnDir === 'asc';
    setDir(isDesc ? 'desc' : 'asc');
    setOrder(orderBy);
  }, []);


  const { error, data, loading } = useQuery(GetUsers, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        search,
      }
    }
  });

  useEffect(() => {
    if (!loading && error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, error]);

  const onDelete = (id: number) => {
    enableOrDisableUser({
      variables: {
        id
      },
      onCompleted: (data) => {
        if (data && data?.activateDeactivateUser && data?.activateDeactivateUser?.success) {
          notification({ message: "User deleted successfully!" });
        }
      },
      onError: (err) => {
        notification({ message: err.message, type: "danger" });
      },
      refetchQueries: ["GetUsers"]
    })
  }
  const handleDestroy = (id: number) => {
    confirmAlert({
      customUI: ({ onClose }) =>
        <CustomAlert
          id={id}
          onClose={onClose}
          message="Are you sure you want to deactivate this user"
          callback={onDelete}
          theme={theme}
        />
    })
  };

  let columns: any = [
    {
      name: "firstName",
      label: "First Name",
      width: 100,
    },
    {
      name: "lastName",
      label: "Last Name",
      align: "center",
      width: 100,
    }
  ];

  if (isSystem) {
    columns = [...columns, ...[
      {
        name: "email",
        label: "Email",
        align: "center",
        width: 100,
      },
      {
        name: "phone",
        label: "Mobile Number",
        align: "center",
      },
      {
        name: "type",
        label: "Type",
        align: "center",
      },
      {
        name: "action",
        label: "",
        align: "left"
      }
    ]]
  } else {
    columns = [...columns, ...[
      {
        name: "type",
        label: "Type",
        align: "center",
      },
      {
        name: "action",
        label: "",
        align: "left"
      }
    ]]
  }

  const rows =
    (data?.users || []).map((row: any) => {
      const action = (
        <TableActionCell
          hideEdit={true}
          editLabel="Edit User"
          onEdit={() => { }}
          hideDestroy={true}
          tableRow={{ rowId: row.userId }}
          onDestroy={handleDestroy}
        />
      );

      const type = row?.userType?.name
      return {
        ...row,
        action,
        type
      };
    });

  const handleRowClick = (row: any) => {
    // navigate(`/users/${row.userId}`)
  }

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <AddButton show={show} setShow={setShow} setSearch={setSearch} />
      <List
        initialOrder={order}
        initialDir={dir}
        rows={rows}
        loading={loading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Users List"
        handleRowClick={handleRowClick}
        rowPerPage={50}
        onSelectedDelete={() => { }}
      />
    </div>
  );
};

export default UsersList;
