import { gql } from "@apollo/client";

export default gql`
query GetSubjects($where: JSON){
  subjects(where: $where) {
    id
    name
    nameHi
  }
}
`