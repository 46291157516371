import { gql } from "@apollo/client";

export default gql`
query GetQuizzes($where: JSON, $limit: Int, $offset: Int){
  quizzes(where: $where, limit: $limit, offset: $offset) {
    totalCount
    quizzes {
      id
      title
      titleHi
      totalQuestions
      description
      descriptionHi
      isActive
      isPersonal
      category {
        id
        name
        nameHi
      }
      subject {
        id
        name
        nameHi
      }
      subCategory {
        id
        name
        nameHi
      }
      _count {
        questions
      }
    }
  }
}
`