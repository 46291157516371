import React from 'react'

interface Props {
    placeholder: string
    label: string
    customId: string
    register: any
    errors: string | undefined;
    readOnly?: boolean;
    numRows?: number;
    onFocus?: (v: string) => void
}

const Textarea: React.FC<Props> = ({customId, errors, label, placeholder, register, readOnly = false, onFocus, numRows = 4}) => {
    return (
        <div style={{ marginBottom: 14 }}>
            <label htmlFor={customId} className="text-label" style={{ display: 'block', color: '#1346a3', fontWeight: "bold" }}>
                {label}
            </label>
            <textarea rows={numRows} readOnly={readOnly} placeholder={placeholder} onFocus={() => onFocus ? onFocus(customId) : {}} {...register(customId)} className="text-field px-2 rounded"></textarea>
            {errors && <p style={{ color: 'red' }}>{errors}</p>}
        </div>
    )
}

export default Textarea