import React, { useState, useCallback, useEffect } from 'react'
import AddButton from '../components/common/AddButton'
import List from '../components/lists/List'
import type { Direction } from '../service/types'
import { useQuery } from '@apollo/client'
import { notification } from "../service/index"
import TableActionCell from '../components/lists/TableActionCell'
import AbstractModal from '../components/AbstractModal'
import CreateSubCategory from '../components/forms/sub-category/CreateSubCategory'
import GetSubCategories from '../graphql/queries/GetSubCategories'
import CategoryFilter from '../components/filters/CategoryFilter'
import SubjectFilter from '../components/filters/SubjectFilter'
import EditSubCategory from '../components/forms/sub-category/EditSubCategory'

const SubCategory:React.FC = () => {
  const [show, setShow] = useState<boolean>(false)
  const [showEdit, setShowEdit] = useState<boolean>(false)
  const [editId, setEditId] = useState<number>()
  const [order, setOrder] = useState<String>("name");
  const [dir, setDir] = useState<Direction>('asc');
  const [categoryId, setCategory] = useState<number>()
  const [subjectId, setSubjectId] = useState<number>()
  const [search, setSearch] = useState<string>('')
  const handleSortingChange = useCallback((columnDir: String, orderBy: String) => {
    const isDesc = columnDir === 'asc';
    setDir(isDesc ? 'desc' : 'asc');
    setOrder(orderBy);
  }, []);

  const { error, data, loading } = useQuery(GetSubCategories, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        categoryId,
        search
      }
    }
  });

  useEffect(() => {
    if (!loading && error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, error]);

  const columns = [
    {
      name: "name",
      label: "Name",
      width: 100,
    },
    {
      name: "nameHi",
      label: "Name (In Hindi)",
      width: 100,
    },
    {
      name: "description",
      label: "Description",
      width: 100,
    },
    {
      name: "action",
      label: "",
      align: "left"
    }
  ];

  const handleEdit = useCallback((id: number) => {
    setEditId(id)
    setShowEdit(true)
  }, [])

  const rows =
    (data?.subCategories || []).map((row: any) => {
      const action = (
        <TableActionCell
          hideEdit={false}
          editLabel="Edit User"
          onEdit={handleEdit}
          hideDestroy={false}
          tableRow={{ rowId: row.id }}
          onDestroy={() => {}}
        />
      );

      return {
        ...row,
        action,
      };
    });

    const handleCategorySelect = useCallback((v:number) => {
      setCategory(v)
    },[])

    const handleSubjectSelect = useCallback((v:number) => {
      setSubjectId(v)
    },[])

    const handleSearch = useCallback((v:string) => {
      setSearch(v)
    },[])
    
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <AddButton 
        show={show} 
        setShow={setShow} 
        setSearch={handleSearch}
        filter={<SubjectFilter value={subjectId} setOptionValue={handleSubjectSelect} />}
        filter2={<CategoryFilter setOptionValue={handleCategorySelect} value={categoryId} subjectId={subjectId}  />} 
      />
      <List
        initialOrder={order}
        initialDir={dir}
        rows={rows}
        loading={loading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Sub Categories"
        rowPerPage={50}
        onSelectedDelete={() => { }}
      />
      <AbstractModal 
        Component={<CreateSubCategory handleClose={() => setShow(false)} />} 
        handleClose={() => setShow(false)} 
        show={show} 
      />
      <AbstractModal 
        Component={<EditSubCategory id={editId} handleClose={() => setShowEdit(false)} />} 
        handleClose={() => setShowEdit(false)} 
        show={showEdit} 
      />
    </div>
  )
}

export default SubCategory