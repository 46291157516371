import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import type { SelectType } from "../../service/types"

interface Props{
  errors: any;
  data: SelectType[];
  register: any;
  name: string;
  label: string;
  value?: string;
  setValue?: (x:any, y: any) => void;
  disabled?: boolean
}

const SelectField: React.FC<Props> = ({
  errors,
  data,
  register,
  name,
  label,
  value,
  setValue,
  disabled = false
}) => {
   const [optionValue,setOptionValue]=useState<string>('')
   useEffect(() => {
    if(value && setValue){
      setOptionValue(value)
      setValue(name, value)
    }
   }, [value, setValue, name])
   
  return (
      <>
      <FormControl className="mb-4 mt-2" style={{width: "100%"}} variant="standard">
        <InputLabel
          id="demo-simple-select-label"
          style={{ fontWeight: "bold" }}>
          {label}
        </InputLabel>
        <Select {...register(name)} disabled={disabled} label={label} value={optionValue} onChange={(e: any)=> setOptionValue(e.target.value)}>
          {data.length > 0 ? (
            data.map((item:any, key) => (
              <MenuItem key={key} value={item.value}>
                {item.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">{label}</MenuItem>
          )}
        </Select>
        {errors[name]?.message && <p style={{ color: "red" }}>{errors[name]?.message}</p>}
      </FormControl>
      </>
  );
};

export default SelectField;
