import { gql } from "@apollo/client";

export default gql`
mutation Login($input: LoginInput!){
  login(loginInput: $input) {
   user {
        id
        email
        firstName
        lastName
        phone
        isActive
        userTypeId
        educationId
        userType {
          id
          name
        }
        education {
          id
          label
        }
   } 
   token
  }
}
`