import { gql } from "@apollo/client";

export default gql`
query GetCategory($categoryId: Int!){
  category(id: $categoryId) {
    id
    name
    nameHi
    subjectId
  }
}
`