import React, { useCallback, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from '../../common/TextField';
import SubmitButton from '../../common/SubmitButtom';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { RootState } from '../../../reducers/store';
import { notification, themes } from '../../../service';
import Textarea from '../../common/Textarea';
import NumberField from '../../common/NumberField';
import { useMutation } from '@apollo/client';
import Campaign from '../../../graphql/mutations/Campaign';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import DatePicker from '../../common/DatePicker';
import { handleTranslate } from '../../../service/translate';

interface CampaignCreateType {
    title: string
    titleHi: string
    description: string
    descriptionHi: string
    coins: number;
    startAt: Date;
    endAt: Date;
}

interface Props {
    handleClose: () => void;
}

const schema = yup
    .object({
        title: yup.string().required("Title is required!"),
        titleHi: yup.string().required("Title (In hindi) is required!"),
        description: yup.string().required("Description is required!"),
        descriptionHi: yup.string().required("Description (In hindi) is required!"),
        coins: yup.number().required("Total no. of questions is required!"),
        startAt: yup.date().required("Start Date is required!"),
        endAt: yup.date().required("End Date is required!")
    })
    .required()

const CreateCampaign: React.FC<Props> = ({ handleClose }) => {
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const [startAt, setStartAt] = useState<any>(null)
    const [endAt, setEndAt] = useState<any>(null)
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const [createCamapign] = useMutation(Campaign)

    const onFormSubmit = (v: CampaignCreateType) => {
        console.log('v', v);
        
        createCamapign({
            variables: {
                input: v
            },
            onCompleted: (data) => {
                if(data){
                    notification({message: 'Campaign created successfully!'})
                }
                handleClose()
            },
            onError: (err) => {
                console.log('error');
                notification({ message: err.message, type: 'danger' })
            },
            refetchQueries: ['GetCampaigns']
        })
    }

    const handleDateChange = (v: any, isStart = false) => {
        if (isStart) {
            setValue('startAt', v)
            setStartAt(v)
        } else {
            setValue('endAt', v)
            setEndAt(v)
        }
    }

    const handleFocus = useCallback(async (idx: any) => {
        const name:any = idx.slice(0, -2)
        const hindi = await handleTranslate(getValues(name))
        setValue(idx, hindi)
    }, [getValues, setValue])

    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Create Campaign
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <TextField
                    label="Title"
                    placeholder="Enter title"
                    customId="title"
                    type="text"
                    register={register}
                    errors={errors?.title?.message}
                />
                <TextField
                    label="Title (In Hindi)"
                    placeholder="Enter title (In hindi)"
                    customId="titleHi"
                    type="text"
                    register={register}
                    onFocus={handleFocus}
                    errors={errors?.titleHi?.message}
                />
                <Textarea
                    label="Description"
                    placeholder="Enter description"
                    customId="description"
                    register={register}
                    errors={errors?.description?.message}
                />
                <Textarea
                    label="Description (In Hindi)"
                    placeholder="Enter description (In hindi)"
                    customId="descriptionHi"
                    onFocus={handleFocus}
                    register={register}
                    errors={errors?.descriptionHi?.message}
                />
                <NumberField
                    label="Entry coins"
                    placeholder="Enter entry fees"
                    customId="coins"
                    register={register}
                    min={50}
                    max={1000}
                    errors={errors?.coins?.message}
                />
                <div className='mb-3 mt-2'>
                    <DatePicker>
                        <DateTimePicker
                            label="Start Date Time"
                            value={startAt}
                            onChange={(newValue) => handleDateChange(newValue, true)}
                        />
                    </DatePicker>
                </div>
                <DatePicker>
                    <DateTimePicker
                        label="End Date Time"
                        value={endAt}
                        onChange={(newValue) => handleDateChange(newValue)}
                    />
                </DatePicker>
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default CreateCampaign