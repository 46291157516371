import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from '@apollo/client';
import * as yup from "yup";
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'

import TextField from '../../common/TextField';
import SubmitButton from '../../common/SubmitButtom';
import { RootState } from '../../../reducers/store';
import { notification, themes } from '../../../service';
import SelectCategory from '../../select/CategorySelect';
import UpdateSubCategory from '../../../graphql/mutations/UpdateSubCategory';
import GetSubCategory from '../../../graphql/queries/GetSubCategory';
import Textarea from '../../common/Textarea';

interface SubCategoryCreateType {
    name: string;
    nameHi: string;
    categoryId: number;
    description?: string | null;
}

interface Props{
    handleClose: () => void;
    id?: number
}

const schema = yup
    .object({
        name: yup.string().required("Sub Category name is required!"),
        nameHi: yup.string().required("Sub Category name (In hindi) is required!"),
        description: yup.string().nullable(),
        categoryId: yup.number().required("Category is required!")
    })
    .required()

const EditSubCategory: React.FC<Props> = ({handleClose, id}) => {
    const [categoryId, setCategoryId] = useState<number>()
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const {data, loading, error} = useQuery(GetSubCategory, {
        fetchPolicy: 'network-only',
        variables: {
            id
        }
    })
    const [updateSubCategory] = useMutation(UpdateSubCategory)
    
    useEffect(() => {
        if(!loading && data && data.subCategory){
            const {name, description, nameHi} = data.subCategory;
            setValue('name', name)
            setValue('description', description)
            setValue('nameHi', nameHi)
            setCategoryId(data.subCategory.categoryId)
        }
    }, [loading, data, error, setValue])

    const onFormSubmit = (v: SubCategoryCreateType) => {
        updateSubCategory({
            variables: {
                input: {...v, id}
            },
            onCompleted: (data) => {
                if(data){
                    notification({message: 'Sub category updated successfully!'})
                }
                handleClose()
            },
            onError: (err) => {
                notification({message: err.message, type: 'danger'})
            },
            refetchQueries: ['GetSubCategories']
        })
    }

    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Edit Sub Category
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <SelectCategory
                    errors={errors}
                    label='Select Category'
                    name='categoryId'
                    register={register}
                    value={categoryId?.toString()}
                    setValue={setValue}
                />
                <TextField
                    label="Sub Category"
                    placeholder="Enter sub category name"
                    customId="name"
                    type="text"
                    register={register}
                    errors={errors?.name?.message}
                />
                <TextField
                    label="Sub Category (In Hindi)"
                    placeholder="Enter sub category name (In hindi)"
                    customId="nameHi"
                    type="text"
                    register={register}
                    errors={errors?.nameHi?.message}
                />
                <Textarea
                    label="Description"
                    placeholder="Enter description"
                    customId="description"
                    register={register}
                    errors={errors?.description?.message}
                />
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default EditSubCategory