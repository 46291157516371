import React, { useEffect, useRef, useState } from 'react'
import { BsThreeDotsVertical } from "react-icons/bs";
import { ContentType } from '../../../service/types';
import AbstractModal from '../../AbstractModal';
import EditContent from '../../forms/content/EditContent';
import { Link } from 'react-router-dom';

interface Props{
  item: ContentType;
}

const ContentItem:React.FC<Props> = ({ item }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [editModal, setEditModal] = useState(false);
  
  const popupRef = useRef<HTMLDivElement>(null);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopup]);
  
  return (
    <div className='campaign-item position-relative'>
      <div className='d-flex justify-content-between'>
        <div style={{width: '90%'}}>
          <h3 style={{fontSize: 18, color: '#b6c8d9'}}>{item.title}</h3>
        </div>
        <span role='button' onClick={togglePopup} style={{ position: 'relative' }}>
          <BsThreeDotsVertical style={{fontSize: 24, color: '#b6c8d9'}} />
          {showPopup && (
            <div className='campaign-popup' ref={popupRef}>
              <span role='button' className='d-block popup-item' onClick={() => setEditModal(true)}>Edit</span>
              <Link to={`/content/${item.id}`} style={{textDecoration: 'none', color: '#f5f5f5'}}>
                <span role='button' className='d-block popup-item' onClick={() => console.log("clicked")}>View</span>
              </Link>
            </div>
          )}
        </span>
      </div>
      <div>
        <p style={{color: '#b6c8d9'}}>{item.content}</p>
      </div>
      <AbstractModal 
        Component={
          <EditContent 
            id={item.id} 
            handleClose={() => setEditModal(false)} 
          />
        } 
        handleClose={() => setEditModal(false)} 
        show={editModal} 
      />
    </div>
  )
}

export default ContentItem