import React, { useCallback, useState } from 'react'
import { Button, Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { RootState } from '../../../reducers/store';
import { notification, themes } from '../../../service';
import { useMutation } from '@apollo/client';
import SubjectFilter from '../../filters/SubjectFilter';
import CategoryFilter from '../../filters/CategoryFilter';
import AddCampaignQuiz from '../../../graphql/mutations/AddCampaignQuiz';
import MultiSelect from '../../common/MultiSelect';

interface Props {
    handleClose: () => void;
    campaignId?: number;
}

const CreateCampaignQuiz: React.FC<Props> = ({ handleClose, campaignId }) => {
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const [subjectId, setSubjectId] = useState<number>()
    const [categoryId, setCategoryId] = useState<number>()
    const [quizId, setQuizId] = useState<string[]>([])

    const [addCampaignQuiz] = useMutation(AddCampaignQuiz)

    const onFormSubmit = () => {
        if(!quizId.length){
            return notification({message: 'Quizzes are required!', type: 'warning'})
        }
        console.log('quizzes', quizId);
        
        addCampaignQuiz({
            variables: {
                campaignId,
                quizId: quizId.map((item) => Number(item))
            },
            onCompleted: (data) => {
                console.log('data', data);
                handleClose()
            },
            onError: (err) => {
                notification({message: err.message, type: 'danger'})
            },
            refetchQueries: ['GetCampaigns']
        })
    }

    const handleSubject = useCallback((id: number) => {
        setSubjectId(id)
    }, [])

    const handleCategory = useCallback((id: number) => {
        setCategoryId(id)
    }, [])
    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Add Campaign Quiz
            </Typography>
            <SubjectFilter
                setOptionValue={handleSubject}
                value={subjectId}
            />
            <CategoryFilter
                setOptionValue={handleCategory}
                value={categoryId}
                subjectId={subjectId}
            />
            <MultiSelect label='Select Quizzes' onChange={(v) => setQuizId(v)} value={quizId} categoryId={categoryId} subjectId={subjectId} />
            <div className='mt-4 d-flex justify-content-end'>
                <Button onClick={onFormSubmit} variant='contained'>Submit</Button>
            </div>
        </div>
    )
}

export default CreateCampaignQuiz