import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from '../common/TextField';
import SubmitButton from '../common/SubmitButtom';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducers/store';
import { notification, themes } from '../../service';
import { useMutation, useQuery } from '@apollo/client';
import Textarea from '../common/Textarea';
import SelectSubCategory from '../select/SubCategorySelect';
import Topic from '../../graphql/mutations/Topic';
import GetTopic from '../../graphql/queries/GetTopic';
import SelectCategory from '../select/CategorySelect';

interface TopicEditType {
    title: string;
    description: string;
    categoryId: number;
    subCategoryId: number;
}

interface Props{
    handleClose: () => void;
    id?: number
}

const schema = yup
    .object({
        title: yup.string().required("Title is required!"),
        description: yup.string().required("Description is required!"),
        categoryId: yup.number().required("Category is required!"),
        subCategoryId: yup.number().required("Sub Category is required!")
    })
    .required()

const EditTopic: React.FC<Props> = ({handleClose, id}) => {
    const [subCategoryId, setSubCategoryId] = useState<number>()
    const [categoryId, setCategoryId] = useState<number>()
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const [createTopic] = useMutation(Topic)

    const {data, loading, error} = useQuery(GetTopic, {
        fetchPolicy: 'network-only',
        variables: {
            id
        }
    })

    useEffect(() => {
        if(!loading && data && data.topic){
            const {title, description, categoryId, subCategoryId} = data.topic
            setValue('title', title)
            setValue('description', description)
            setCategoryId(categoryId)
            setSubCategoryId(subCategoryId)
        }
    }, [loading, data, error, setValue])

    const onFormSubmit = (v: TopicEditType) => {
        createTopic({
            variables: {
                input: v
            },
            onCompleted: (data) => {
                console.log('data', data);
                handleClose()
            },
            onError: (err) => {
                console.log('error');
                notification({message: err.message, type: 'danger'})
            },
            refetchQueries: ['GetTopics']
        })
    }

    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Edit Topics
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <SelectCategory 
                    errors={errors} 
                    label='Select Category' 
                    name='categoryId' 
                    register={register} 
                    value={categoryId?.toString()}
                    setValue={setValue}
                />
                <SelectSubCategory
                    errors={errors}
                    label='Select Sub Category'
                    name='subCategoryId'
                    register={register}
                    value={subCategoryId?.toString()}
                    setValue={setValue}
                />
                <TextField
                    label="Title"
                    placeholder="Enter title"
                    customId="title"
                    type="text"
                    register={register}
                    errors={errors?.title?.message}
                />
                <Textarea
                    label="Description"
                    placeholder="Enter description"
                    customId="description"
                    register={register}
                    errors={errors?.description?.message}
                />
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default EditTopic