import React from "react";
import { debounce } from "@mui/material";
import { themes } from "../../service";

type themeType = 'dark'|'light'
interface Props {
  theme?: themeType
  setSearch: (e: any) => void
}
const SearchBar: React.FC<Props> = ({ setSearch, theme = 'light'}) => {
  const handleSearch = debounce((event) => {
    setSearch(event.target.value)
  }, 500)
  return (
    <div style={{ width: "50%" }}>
      <input
        type="text"
        placeholder="Search"
        className="search-input"

        onChange={handleSearch}
        style={{backgroundColor: themes[theme].sidebar.backgroundColor, color: themes[theme].sidebar.color, height: 40}}
      />
    </div>
  );
};

export default SearchBar;
