import { gql } from "@apollo/client";

export default gql`
query GetCampaigns($where: JSON){
  campaigns(where: $where) {
    id
    coins
    endAt
    startAt
    description
    descriptionHi
    title
    titleHi
    isCompleted
    _count {
        participants
        campaignQuiz
    }
  }
}
`