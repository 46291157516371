import React, { useState, useCallback, useEffect } from 'react'
import List from '../components/lists/List'
import type { Direction } from '../service/types'
import { useQuery } from '@apollo/client'
import { notification } from "../service/index"
import TableActionCell from '../components/lists/TableActionCell'
import AbstractModal from '../components/AbstractModal'
import GetQuestions from '../graphql/queries/GetQuestions'
import EditQuestion from '../components/forms/quiz/EditQuestion'
import AddButton from '../components/common/AddButton'
import QuizFilter from '../components/filters/QuizFilter'

const Questions:React.FC = () => {
  const [show, setShow] = useState(false)
  const [editId, setEditId] = useState<number>()
  const [quizId, setQuizId] = useState<number>()
  const [order, setOrder] = useState<String>("title");
  const [dir, setDir] = useState<Direction>('asc');
  const [search, setSearch] = useState<string>('')

  const handleSortingChange = useCallback((columnDir: String, orderBy: String) => {
    const isDesc = columnDir === 'asc';
    setDir(isDesc ? 'desc' : 'asc');
    setOrder(orderBy);
  }, []);

  const { error, data, loading } = useQuery(GetQuestions, {
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        search,
        quizId
      }
    }
  });

  useEffect(() => {
    if (!loading && error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, error]);

  const columns = [
    {
      name: "question",
      label: "Title",
      width: 100,
    },
    {
      name: "optionA",
      label: "Option A",
      align: "center",
    },
    {
      name: "optionB",
      label: "Option B",
      align: "center",
    },
    {
      name: "optionC",
      label: "Option C",
      align: "center",
    },
    {
      name: "optionD",
      label: "Option D",
      align: "center",
    },
    {
      name: "action",
      label: "",
      align: "left"
    }
  ];

  const handleEdit = (id: number) => {
    setEditId(id)
    setShow(true)
  }
  
  const rows =
    (data?.questions || []).map((row: any) => {
      
      const action = (
        <TableActionCell
          hideEdit={false}
          editLabel="Edit question"
          onEdit={handleEdit}
          hideDestroy={true}
          tableRow={{ rowId: row.id }}
          onDestroy={() => {}}
        />
      );
      const optionA = row.options[0]
      const optionB = row.options[1]
      const optionC = row.options[2]
      const optionD = row.options[3]
      return {
        ...row,
        optionA: <span style={{color: optionA?.isCorrect ? 'green':''}}>{optionA?.label}</span>,
        optionB: <span style={{color: optionB?.isCorrect ? 'green':''}}>{optionB?.label}</span>,
        optionC: <span style={{color: optionC?.isCorrect ? 'green':''}}>{optionC?.label}</span>,
        optionD: <span style={{color: optionD?.isCorrect ? 'green':''}}>{optionD?.label}</span>,
        action,
      };
    });

    const handleQuizChange = useCallback((v:number) => {
      setQuizId(v)
    },[])

    const handleSearch = useCallback((v:string) => {
      setSearch(v)
    },[])

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <AddButton 
        setSearch={handleSearch} 
        showButton={false} 
        filter={<QuizFilter value={quizId} setOptionValue={handleQuizChange} />} 
      />
      <List
        initialOrder={order}
        initialDir={dir}
        rows={rows}
        loading={loading}
        columns={columns}
        onSortingChange={handleSortingChange}
        title="Questions"
        rowPerPage={50}
        onSelectedDelete={() => { }}
      />
      <AbstractModal 
        Component={<EditQuestion questionId={editId}
        handleClose={() => setShow(false)} />} 
        handleClose={() => setShow(false)} 
        show={show}
      />
    </div>
  )
}

export default Questions