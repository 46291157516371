import React, { useCallback, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import * as yup from "yup"
import { useMutation } from '@apollo/client';

import TextField from '../../common/TextField';
import SubmitButton from '../../common/SubmitButtom';
import { RootState } from '../../../reducers/store';
import { notification, themes } from '../../../service';
import Textarea from '../../common/Textarea';
import SelectSubCategory from '../../select/SubCategorySelect';
import CategoryFilter from '../../filters/CategoryFilter';
import Content from '../../../graphql/mutations/Content';
import SubjectFilter from '../../filters/SubjectFilter';
import { handleTranslate } from '../../../service/translate';

interface ContentCreateType {
    title: string
    titleHi: string
    content: string
    contentHi: string
    fileUrl?: string|null;
    categoryId: number;
    subCategoryId: number;
    list?: string | null;
}

interface Props{
    handleClose: () => void;
}

const schema = yup
    .object({
        title: yup.string().required("Title is required!"),
        titleHi: yup.string().required("Title (In hindi) is required!"),
        content: yup.string().required("Content is required!"),
        contentHi: yup.string().required("Content (In hindi) is required!"),
        fileUrl: yup.string().nullable(),
        list: yup.string().nullable(),
        categoryId: yup.number().required("Category is required!"),
        subCategoryId: yup.number().required("Sub Category is required!"),
    })
    .required()

const CreateContent: React.FC<Props> = ({handleClose}) => {
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const [categoryId, setCategoryId] = useState<number>()
    const [subjectId, setSubjectId] = useState<number>()
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const [createContent] = useMutation(Content)

    const onFormSubmit = (v: ContentCreateType) => {
        const { list } = v;
        const item = list?.split('__')
        createContent({
            variables: {
                input: {...v, list: item}
            },
            onCompleted: (data) => {
                console.log('data', data);
                // handleClose()
            },
            onError: (err) => {
                console.log('error');
                notification({message: err.message, type: 'danger'})
            },
            refetchQueries: ['GetContents']
        })
    }

    const handleCategoryChange = useCallback((v: number) => {
        setCategoryId(v)
        setValue('categoryId', v)
    },[setValue])
    
    const handleSubjectChange = useCallback((v: number) => {
        setSubjectId(v)
    },[])

    const handleFocus = useCallback(async (idx: any) => {
        const name:any = idx.slice(0, -2)
        const hindi = await handleTranslate(getValues(name))
        setValue(idx, hindi)
    }, [getValues, setValue])

    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Create Content
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <SubjectFilter setOptionValue={handleSubjectChange} value={subjectId} />
                <CategoryFilter setOptionValue={handleCategoryChange} value={categoryId} subjectId={subjectId} />
                <SelectSubCategory
                    errors={errors}
                    label='Select Sub Category'
                    name='subCategoryId'
                    register={register}
                    categoryId={categoryId}
                    disabled={!categoryId}
                />
                <TextField
                    label="Title"
                    placeholder="Enter title"
                    customId="title"
                    type="text"
                    register={register}
                    errors={errors?.title?.message}
                />
                <TextField
                    label="Title (In Hindi)"
                    placeholder="Enter title (In hindi)"
                    customId="titleHi"
                    onFocus={handleFocus}
                    type="text"
                    register={register}
                    errors={errors?.titleHi?.message}
                />
                <Textarea
                    label="Description"
                    placeholder="Enter description"
                    customId="content"
                    register={register}
                    errors={errors?.content?.message}
                />
                <Textarea
                    label="Description (In Hindi)"
                    placeholder="Enter description (In hindi)"
                    customId="contentHi"
                    onFocus={handleFocus}
                    register={register}
                    errors={errors?.contentHi?.message}
                />
                <Textarea
                    label="List"
                    placeholder="Enter list of formula, data etc..."
                    customId="list"
                    register={register}
                    errors={errors?.list?.message}
                />
                <TextField
                    label="File"
                    placeholder="Enter file url"
                    customId="fileUrl"
                    register={register}
                    errors={errors?.fileUrl?.message}
                />
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default CreateContent