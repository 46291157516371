import { gql } from "@apollo/client";

export default gql`
query GetQuestions($where: JSON){
  questions(where: $where) {
    id
    question
    questionHi
    options {
      id
      isCorrect
      label
      labelHi
    }
  }
}
`