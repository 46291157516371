import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { useQuery } from '@apollo/client'
import { notification } from '../../service'
import { themes } from '../../service'
import type { CategoryType, Option } from '../../service/types'
import GetSubCategories from '../../graphql/queries/GetSubCategories'
import { useSelector } from 'react-redux'
import { RootState } from '../../reducers/store'

interface Props {
    setOptionValue: (v: number) => void;
    value?: number;
    categoryId?: number;
}

const SubCategoryFilter: React.FC<Props> = ({
    value, setOptionValue, categoryId
}) => {
    const [subCategories, setSubCategories] = useState<Option[]>([])
    const {theme} = useSelector((state: RootState) => state.sidebar)
    const { data, loading, error } = useQuery(GetSubCategories, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                categoryId
            }
        }
    })

    useEffect(() => {
        if (!loading && data && data.subCategories) {
            const formatedData = data.subCategories.map((item: CategoryType) => ({ value: item.id, label: item.name }))
            setSubCategories(formatedData)
        }
        if (error) {
            notification({ message: error.message, type: "danger" });
        }
    }, [loading, error, data])
    return (
        <div className='mt-2'>
            <FormControl style={{ width: "100%", marginTop: 15 }} variant="standard">
                <InputLabel
                    id="demo-simple-select-label"
                    style={{ fontWeight: "bold", color: themes[theme].sidebar.color }}>
                    Select Sub Category
                </InputLabel>
                <Select label="Select Sub Category" style={{ color: themes[theme].sidebar.color }} value={value} onChange={(e: any) => setOptionValue(e.target.value)}>
                    {subCategories.length > 0 ? (
                        subCategories.map((item: Option, key: number) => (
                            <MenuItem key={key} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem value="">Select Category</MenuItem>
                    )}
                </Select>
                {/* <SelectSearch
          label='Select Category' 
          options={categories} 
          selectedValue={value}
          setSelectedValue={setOptionValue} 
        /> */}
            </FormControl>
        </div>
    )
}

export default SubCategoryFilter