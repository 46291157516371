import React, { useState, useCallback, useEffect } from 'react'
import AddButton from '../components/common/AddButton'
import List from '../components/lists/List'
import type { Direction } from '../service/types'
import { useQuery } from '@apollo/client'
import { notification } from "../service/index"
import TableActionCell from '../components/lists/TableActionCell'
import AbstractModal from '../components/AbstractModal'
import CategoryFilter from '../components/filters/CategoryFilter'
import SubjectFilter from '../components/filters/SubjectFilter'
import SubCategoryFilter from '../components/filters/SubCategoryFilter'
import CreateTopic from '../components/forms/CreateTopic'
import GetTopics from '../graphql/queries/GetTopics'
import EditTopic from '../components/forms/EditTopic'
import LatexWrapper from '../components/common/LatexWrapper'
import { isLatex } from '../service/index'

const Topics: React.FC = () => {
    const [show, setShow] = useState<boolean>(false)
    const [showEdit, setShowEdit] = useState<boolean>(false)
    const [editId, setEditId] = useState<number>()
    const [order, setOrder] = useState<String>("name");
    const [dir, setDir] = useState<Direction>('asc');
    const [categoryId, setCategory] = useState<number>()
    const [subjectId, setSubjectId] = useState<number>()
    const [subCategoryId, setSubCategoryId] = useState<number>()
    const [search, setSearch] = useState<string>('')
    const handleSortingChange = useCallback((columnDir: String, orderBy: String) => {
        const isDesc = columnDir === 'asc';
        setDir(isDesc ? 'desc' : 'asc');
        setOrder(orderBy);
    }, []);

    const { error, data, loading } = useQuery(GetTopics, {
        fetchPolicy: "cache-and-network",
        variables: {
            where: {
                search
            }
        }
    });

    useEffect(() => {
        if (!loading && error && error.message) {
            notification({ message: error.message, type: "danger" });
        }
    }, [loading, error]);

    const columns = [
        {
            name: "title",
            label: "Title",
            width: 100,
        },
        {
            name: "description",
            label: "Description",
            width: 150,
        },
        {
            name: "action",
            label: "",
            align: "left"
        }
    ];

    const handleEdit = useCallback((id: number) => {
        setEditId(id)
        setShowEdit(true)
    }, [])

    const rows =
        (data?.topics || []).map((row: any) => {
            const MoreAction = [
                {
                    label: "Add example",
                    action: () => {}
                },
                {
                    label: "Add question",
                    action: () => {}
                },
                {
                    label: "Add Formula",
                    action: () => {}
                },
                {
                    label: "Add content",
                    action: () => {}
                },
                {
                    label: "Add Summary",
                    action: () => {}
                },
            ]
            const action = (
                <TableActionCell
                    hideEdit={false}
                    editLabel="Edit User"
                    onEdit={handleEdit}
                    hideDestroy={false}
                    tableRow={{ rowId: row.id }}
                    onDestroy={() => { }}
                    moreActions={MoreAction}
                />
            );
            let {description} = row;
            description = isLatex(description) ? <LatexWrapper>{description}</LatexWrapper> : description
            return {
                ...row,
                description,
                action,
            };
        });

    const handleCategorySelect = useCallback((v: number) => {
        setCategory(v)
        
    }, [])

    const handleSubCategorySelect = useCallback((v: number) => {
        setSubCategoryId(v)
    }, [])

    const handleSubjectSelect = useCallback((v: number) => {
        setSubjectId(v)
    }, [])

    const handleSearch = useCallback((v: string) => {
        setSearch(v)
    }, [])

    return (
        <div style={{ width: "100%", position: "relative" }}>
            <AddButton
                show={show}
                setShow={setShow}
                setSearch={handleSearch}
                filter3={<SubCategoryFilter setOptionValue={handleSubCategorySelect} categoryId={categoryId} value={subCategoryId} />}
                filter={<SubjectFilter value={subjectId} setOptionValue={handleSubjectSelect} />}
                filter2={<CategoryFilter setOptionValue={handleCategorySelect} value={categoryId} subjectId={subjectId} />}
            />
            <List
                initialOrder={order}
                initialDir={dir}
                rows={rows}
                loading={loading}
                columns={columns}
                onSortingChange={handleSortingChange}
                title="Topics"
                rowPerPage={50}
                onSelectedDelete={() => { }}
            />
            <AbstractModal
                Component={<CreateTopic handleClose={() => setShow(false)} />}
                handleClose={() => setShow(false)}
                show={show}
            />
            <AbstractModal
                Component={<EditTopic id={editId} handleClose={() => setShowEdit(false)} />}
                handleClose={() => setShowEdit(false)}
                show={showEdit}
            />
        </div>
    )
}

export default Topics