import React from 'react'
import { Grid } from '@mui/material'

const PageNotFound: React.FC = () => {
  return (
    <div style={{backgroundColor: '#f0f0f0', height: '100vh'}}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={11} sm={8} lg={6}>
          <img src='/images/404.gif' alt='404-gif' className='w-100 w-100' />
        </Grid>
      </Grid>
    </div>
  )
}

export default PageNotFound