import { gql } from "@apollo/client"

export default gql`
query GetTopic($id: Int!){
  topic(id: $id) {
    id
    categoryId
    description
    subCategoryId
    title
  }
}
`