import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from '../common/TextField';
import SubmitButton from '../common/SubmitButtom';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducers/store';
import { notification, themes } from '../../service';
import { useMutation, useQuery } from '@apollo/client';
import UpdateSubject from '../../graphql/mutations/UpdateSubject';
import GetSubject from '../../graphql/queries/GetSubject';

interface SubjectCreateType {
    name: string;
    nameHi: string;
}

interface Props{
    handleClose: () => void;
    id?: number;
}

const schema = yup
    .object({
        name: yup.string().required("Category name is required!"),
        nameHi: yup.string().required("Category name (In hindi) is required!"),
    })
    .required()

const EditSubject: React.FC<Props> = ({ handleClose, id }) => {
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const [updateSubject] = useMutation(UpdateSubject)

    const {data, loading, error} = useQuery(GetSubject, {
        fetchPolicy: 'network-only',
        variables: {
            subjectId: id
        }
    })

    useEffect(() => {
        if(!loading && data){
            const { name, nameHi } = data.subject;
            setValue('name', name)
            setValue('nameHi', nameHi)
        }
        if(error){
            notification({message: error.message, type: 'danger'})
        }
    }, [loading, data, error])

    const onFormSubmit = (v: SubjectCreateType) => {
        updateSubject({
            variables: {
                input: {...v, id}
            },
            onCompleted: (data) => {
                if(data){
                    notification({message: 'Subject Updated Successfully!'})
                }
                handleClose()
            },
            onError: (err) => {
                console.log('error');
                notification({message: err.message, type: 'danger'})
            },
            refetchQueries: ['GetSubjects']
        })
    }
    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Edit Subject
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <TextField
                    label="Subject"
                    placeholder="Enter subject name"
                    customId="name"
                    type="text"
                    register={register}
                    errors={errors?.name?.message}
                />
                <TextField
                    label="Subject (In Hindi)"
                    placeholder="Enter subject name (in hindi)"
                    customId="nameHi"
                    type="text"
                    register={register}
                    errors={errors?.nameHi?.message}
                />
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default EditSubject