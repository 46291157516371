import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from '../../common/TextField';
import SubmitButton from '../../common/SubmitButtom';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { RootState } from '../../../reducers/store';
import { notification, themes } from '../../../service';
import { useMutation, useQuery } from '@apollo/client';
import GetQuestion from '../../../graphql/queries/GetQuestion';
import { formatOption } from '../../../service';
import type { QuestionCreateType } from '../../../service/types';
import UpdateQuestion from '../../../graphql/mutations/UpdateQuestion';
import { handleTranslate } from '../../../service/translate';
import Textarea from '../../common/Textarea';

interface Props {
    handleClose: () => void;
    questionId?: number;
}

const schema = yup
    .object({
        question: yup.string().required("Title is required!"),
        questionHi: yup.string().required("Title (In hindi) is required!"),
        optionA: yup.string().required("Option A is required!"),
        optionAHi: yup.string().required("Option A (In hindi) is required!"),
        optionB: yup.string().required("Option B is required!"),
        optionBHi: yup.string().required("Option B (In hindi) is required!"),
        optionC: yup.string().required("Option C is required!"),
        optionCHi: yup.string().required("Option C (In hindi) is required!"),
        optionD: yup.string().required("Option D is required!"),
        optionDHi: yup.string().required("Option D (In hindi) is required!"),
        correct: yup.string().required("Correct answer is required")
    })
    .required()

const EditQuestion: React.FC<Props> = ({ handleClose, questionId }) => {
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const { isSystem } = useSelector((state: RootState) => state.app)
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const { data, error, loading } = useQuery(GetQuestion, {
        fetchPolicy: "network-only",
        variables: {
            questionId
        }
    })

    const getHindi = async (data: any) => {
        const { question, options, questionHi } = data.question;

        const corectOption = options.find((item: any) => !!item.isCorrect)
        setValue('question', question)
        setValue('correct', corectOption?.label)
        setValue('optionA', options[0].label)
        setValue('optionB', options[1].label)
        setValue('optionC', options[2].label)
        setValue('optionD', options[3].label)
        if (!questionHi) {
            const quHi = await handleTranslate(question)
            setValue('questionHi', quHi)
        } else {
            setValue('questionHi', questionHi)
        }
        if (options[0].labelHi) {
            setValue('optionAHi', options[0].labelHi)
        } else {
            const optAHi = await handleTranslate(options[0].label)
            setValue('optionAHi', optAHi)
        }
        if (options[1].labelHi) {
            setValue('optionBHi', options[1].labelHi)
        } else {
            const optBHi = await handleTranslate(options[1].label)
            setValue('optionBHi', optBHi)
        }
        if (options[2].labelHi) {
            setValue('optionCHi', options[2].labelHi)
        } else {
            const optCHi = await handleTranslate(options[2].label)
            setValue('optionCHi', optCHi)
        }
        if (options[3].labelHi) {
            setValue('optionDHi', options[3].labelHi)
        } else {
            const optDHi = await handleTranslate(options[3].label)
            setValue('optionDHi', optDHi)
        }
    }

    useEffect(() => {
        if (!loading && data && data.question) {
            getHindi(data)
        }
        if (error) {
            notification({ message: error.message, type: "danger" })
        }
    }, [loading, error, data, setValue])

    const [updateQuestion] = useMutation(UpdateQuestion)

    const onFormSubmit = (v: QuestionCreateType) => {
        const options = formatOption(v)
        if (!options) {
            notification({ message: 'Answer is required!', type: "danger" })
        }
        console.log('option', options);

        updateQuestion({
            variables: {
                input: {
                    id: questionId,
                    question: v.question,
                    questionHi: v.questionHi,
                    options
                }
            },
            onCompleted: (data) => {
                if (data) {
                    handleClose()
                }
            },
            onError: (err) => {
                console.log('error');
                notification({ message: err.message, type: 'danger' })
            },
            refetchQueries: ['GetQuestions']
        })
    }

    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Edit Question
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <Textarea
                    label="Question Title"
                    placeholder="Enter question title"
                    customId="question"
                    readOnly={!isSystem}
                    register={register}
                    errors={errors?.question?.message}
                />
                <Textarea
                    label="Question Title (In hindi)"
                    placeholder="Enter question title (in hindi)"
                    customId="questionHi"
                    register={register}
                    errors={errors?.questionHi?.message}
                />
                <TextField
                    label="Option A"
                    placeholder="Enter option A"
                    customId="optionA"
                    type="text"
                    readOnly={!isSystem}
                    register={register}
                    errors={errors?.optionA?.message}
                />
                <TextField
                    label="Option A (In hindi)"
                    placeholder="Enter option A (In hindi)"
                    customId="optionAHi"
                    type="text"
                    register={register}
                    errors={errors?.optionAHi?.message}
                />
                <TextField
                    label="Option B"
                    placeholder="Enter option B"
                    customId="optionB"
                    type="text"
                    readOnly={!isSystem}
                    register={register}
                    errors={errors?.optionB?.message}
                />
                <TextField
                    label="Option B (In hindi)"
                    placeholder="Enter option B (In hindi)"
                    customId="optionBHi"
                    type="text"
                    register={register}
                    errors={errors?.optionBHi?.message}
                />
                <TextField
                    label="Option C"
                    placeholder="Enter option C"
                    customId="optionC"
                    type="text"
                    readOnly={!isSystem}
                    register={register}
                    errors={errors?.optionC?.message}
                />
                <TextField
                    label="Option C (In hindi)"
                    placeholder="Enter option C (In hindi)"
                    customId="optionCHi"
                    type="text"
                    register={register}
                    errors={errors?.optionCHi?.message}
                />
                <TextField
                    label="Option D"
                    placeholder="Enter option D"
                    customId="optionD"
                    type="text"
                    readOnly={!isSystem}
                    register={register}
                    errors={errors?.optionD?.message}
                />
                <TextField
                    label="Option D (In hindi)"
                    placeholder="Enter option D (In hindi)"
                    customId="optionDHi"
                    type="text"
                    register={register}
                    errors={errors?.optionDHi?.message}
                />
                <TextField
                    label="Correct Option"
                    placeholder="Enter correct option like A, B, C, D"
                    customId="correct"
                    type="text"
                    readOnly={!isSystem}
                    register={register}
                    errors={errors?.correct?.message}
                />
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default EditQuestion