import React from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

interface Props {
    children: React.ReactNode
}

const DatePicker: React.FC<Props> = ({ children }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            {children}
        </LocalizationProvider>
    )
}

export default DatePicker