import React, { useCallback, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from '../../common/TextField';
import SubmitButton from '../../common/SubmitButtom';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { RootState } from '../../../reducers/store';
import { notification, themes } from '../../../service';
import { useMutation } from '@apollo/client';
import Question from '../../../graphql/mutations/Question';
import ProgressBar from '../../common/ProgressBar';
import { formatOption } from '../../../service';
import type { QuestionCreateType } from '../../../service/types';
import { handleTranslate } from '../../../service/translate';

interface Props{
    handleClose: () => void;
    quizId: number;
    total: number;
    questions?: number
}

const schema = yup
    .object({
        question: yup.string().required("Ttile is required!"),
        questionHi: yup.string().nullable(),
        optionA: yup.string().required("Option A is required!"),
        optionAHi: yup.string().nullable(),
        optionB: yup.string().required("Option B is required!"),
        optionBHi: yup.string().nullable(),
        optionC: yup.string().required("Option C is required!"),
        optionCHi: yup.string().nullable(),
        optionD: yup.string().required("Option D is required!"),
        optionDHi: yup.string().nullable(),
        correct: yup.string().required("Correct answer is required")
    })
    .required()

const CreateQuestions: React.FC<Props> = ({ handleClose, quizId, total, questions }) => {
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const [progress, setProgress] = useState(questions ? questions + 1 : 1)
    const {
        register,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const [createQuestion] = useMutation(Question)

    const onFormSubmit = (v: QuestionCreateType) => {
        const options = formatOption(v)
        if(!options){
            notification({message: 'Answer is required!', type: "danger"})
        }
        createQuestion({
            variables: {
                input: {
                    question: v.question,
                    options,
                    quizId
                }
            },
            onCompleted: (data) => {
                console.log('data', data);
                if(progress === total){
                    handleClose()
                }else{
                    reset()
                    setProgress(progress + 1)
                }
            },
            onError: (err) => {
                console.log('error');
                notification({message: err.message, type: 'danger'})
            },
            refetchQueries: ['GetQuizzes', 'GetQuestions']
        })
    }

    const handleFocus = useCallback(async (idx: any) => {
        const name:any = idx.slice(0, -2)
        const hindi = await handleTranslate(getValues(name))
        setValue(idx, hindi)
    }, [getValues, setValue])

    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Create Questions
            </Typography>
            <ProgressBar progress={progress} total={total} />
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <TextField
                    label="Question Title"
                    placeholder="Enter question title"
                    customId="question"
                    type="text"
                    register={register}
                    errors={errors?.question?.message}
                />
                <TextField
                    label="Question Title (In hindi)"
                    placeholder="Enter question title (in hindi)"
                    customId="questionHi"
                    type="text"
                    onFocus={handleFocus}
                    register={register}
                    errors={errors?.questionHi?.message}
                />
                <TextField
                    label="Option A"
                    placeholder="Enter option A"
                    customId="optionA"
                    type="text"
                    register={register}
                    errors={errors?.optionA?.message}
                />
                <TextField
                    label="Option A (In hindi)"
                    placeholder="Enter option A (In hindi)"
                    customId="optionAHi"
                    type="text"
                    onFocus={handleFocus}
                    register={register}
                    errors={errors?.optionAHi?.message}
                />
                <TextField
                    label="Option B"
                    placeholder="Enter option B"
                    customId="optionB"
                    type="text"
                    register={register}
                    errors={errors?.optionB?.message}
                />
                <TextField
                    label="Option B (In hindi)"
                    placeholder="Enter option B (In hindi)"
                    customId="optionBHi"
                    onFocus={handleFocus}
                    type="text"
                    register={register}
                    errors={errors?.optionBHi?.message}
                />
                <TextField
                    label="Option C"
                    placeholder="Enter option C"
                    customId="optionC"
                    type="text"
                    register={register}
                    errors={errors?.optionC?.message}
                />
                <TextField
                    label="Option C (In hindi)"
                    placeholder="Enter option C (In hindi)"
                    customId="optionCHi"
                    onFocus={handleFocus}
                    type="text"
                    register={register}
                    errors={errors?.optionCHi?.message}
                />
                <TextField
                    label="Option D"
                    placeholder="Enter option D"
                    customId="optionD"
                    type="text"
                    register={register}
                    errors={errors?.optionD?.message}
                />
                <TextField
                    label="Option D (In hindi)"
                    placeholder="Enter option D (In hindi)"
                    customId="optionDHi"
                    onFocus={handleFocus}
                    type="text"
                    register={register}
                    errors={errors?.optionDHi?.message}
                />
                <TextField
                    label="Correct Option"
                    placeholder="Enter correct option like A, B, C, D"
                    customId="correct"
                    type="text"
                    register={register}
                    errors={errors?.correct?.message}
                />
                <SubmitButton label={progress < (total||0) ? "Next" : "Submit"} />
            </form>
        </div>
    )
}

export default CreateQuestions