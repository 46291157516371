import React from "react";
import { Grid, Button } from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import SearchBar from "./SearchBar";
import { useSelector } from "react-redux";
import { RootState } from "../../reducers/store";
import { themes } from "../../service";

interface Props {
  show?: boolean;
  setShow?: any;
  showButton?: boolean
  setSearch: (e: any) => void;
  filter?: React.ReactNode;
  filter2?: React.ReactNode;
  filter3?: React.ReactNode;
}
const AddButton: React.FC<Props> = ({ show, setShow, setSearch, showButton = true, filter, filter2, filter3 }) => {
  const { theme } = useSelector((state: RootState) => state.sidebar)

  return (
    <div className="d-flex justify-content-between ms-4 mt-4">
      <div style={{ width: '50%' }} className="d-flex justify-content-between align-items-center">
        <div style={{width: 200}}>{filter}</div>
        <div style={{width: 200}}>{filter2}</div>
        <div style={{width: 200}}>{filter3}</div>
      </div>
      <Grid
        className="d-flex justify-content-end align-items-center"
        style={{
          height: 60,
          width: '50%',
          padding: '11px 20px',
          backgroundColor: themes[theme].sidebar.backgroundColor
        }}
      >
        <SearchBar setSearch={setSearch} theme={theme} />
        {showButton && <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            setShow(!show);
          }}
        >
          <PersonAddAlt1Icon />
        </Button>}
      </Grid>
    </div>
  );
};

export default AddButton;
