import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useQuery } from '@apollo/client';
import GetFilterQuizzes from '../../graphql/queries/GetFilterQuizzes';
import { notification, themes } from '../../service';
import { SelectType } from '../../service/types';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/store';

interface Props {
    label: string;
    onChange: (v: string[]) => void;
    value: string[]
    categoryId?: number;
    subjectId?: number;
}

const MultiSelect: React.FC<Props> = ({ label, onChange, value = [], categoryId, subjectId }) => {
    const [quizzes, setQuizzes] = React.useState<SelectType[]>([])
    const { theme } = useSelector((state: RootState) => state.sidebar)

    const { data, loading, error } = useQuery(GetFilterQuizzes, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                subjectId,
                categoryId
            }
        }
    })

    React.useEffect(() => {
        if (!loading && data && data.filterQuizzes) {
            const formatedData = data.filterQuizzes.map((item: any) => ({ value: item.id, label: item.title }))
            setQuizzes(formatedData)
        }
        if (error) {
            notification({ message: error.message, type: "danger" });
        }
    }, [loading, error, data])

    const handleChange = (event: SelectChangeEvent) => {
        const {
            target: { value },
        } = event;
        onChange(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <div>
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel 
                    id="demo-multiple-name-label"
                    style={{ fontWeight: "bold", color: themes[theme].sidebar.color }}
                >
                    {label}
                </InputLabel>
                <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    // @ts-ignore
                    value={value}
                    onChange={handleChange}
                    input={<OutlinedInput label={label} />}
                >
                    {quizzes.length > 0 ? (
                        quizzes.map((item: SelectType, key: number) => (
                            <MenuItem key={key} value={item.value}>
                                {item.label}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem value="">Select Quiz</MenuItem>
                    )}
                </Select>
            </FormControl>
        </div>
    );
}

export default MultiSelect