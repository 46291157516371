import React, { useEffect, useState } from 'react'
import SelectField from '../common/SelectField'
import { useQuery } from '@apollo/client'
import { notification } from '../../service'
import type { SelectType, CategoryType } from '../../service/types'
import GetSubCategories from '../../graphql/queries/GetSubCategories'

interface Props {
    errors: any;
    register: any;
    name: string;
    label: string;
    value?: string;
    categoryId?: number;
    setValue?: (x: any, y: any) => void;
    disabled?: boolean
}

const SelectSubCategory: React.FC<Props> = ({
    errors, label, name, register,
    setValue, value, categoryId, disabled = false
}) => {
    const [subCategories, setSubCategories] = useState<SelectType[]>([])

    const { data, loading, error } = useQuery(GetSubCategories, {
        fetchPolicy: 'network-only',
        variables: {
            where: {
                categoryId
            }
        }
    })

    useEffect(() => {
        if (!loading && data && data.subCategories) {
            const formatedData = data.subCategories.map((item: CategoryType) => ({ value: item.id, label: item.name }))
            setSubCategories(formatedData)
        }
        if (error) {
            notification({ message: error.message, type: "danger" });
        }
    }, [loading, error, data])
    return (
        <div className='mt-2'>
            <SelectField
                data={subCategories}
                errors={errors}
                label={label}
                name={name}
                register={register}
                setValue={setValue}
                value={value}
                disabled={disabled}
            />
        </div>
    )
}

export default SelectSubCategory