import React, { useCallback } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { useMutation } from '@apollo/client';

import TextField from '../../common/TextField';
import SubmitButton from '../../common/SubmitButtom';
import { RootState } from '../../../reducers/store';
import { notification, themes } from '../../../service';
import Category from '../../../graphql/mutations/Category';
import SubjectSelect from '../../select/SubjectSelect';
import EducationSelect from '../../select/EducationSelect';
import Textarea from '../../common/Textarea';
import { handleTranslate } from '../../../service/translate';

interface CategoryCreateType {
    name: string;
    nameHi: string;
    subjectId: number;
    fromEducationId: number;
    toEducationId: number;
    description?: string | null;
}

interface Props{
    handleClose: () => void;
}

const schema = yup
    .object({
        name: yup.string().required("Category name is required!"),
        nameHi: yup.string().required("Category name (In hindi) is required!"),
        subjectId: yup.number().required("Subject is required!"),
        fromEducationId: yup.number().required("From Class is required!"),
        toEducationId: yup.number().required("To class is required!"),
        description: yup.string().nullable(),
    })
    .required()

const CreateCategory: React.FC<Props> = ({handleClose}) => {
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const [createCategory] = useMutation(Category)

    const onFormSubmit = (v: CategoryCreateType) => {
        createCategory({
            variables: {
                input: v
            },
            onCompleted: (data) => {
                if(data){
                    notification({message: 'Category created successfully!'})
                }
                handleClose()
            },
            onError: (err) => {
                console.log('error');
                notification({message: err.message, type: 'danger'})
            },
            refetchQueries: ['GetQuizzes', 'GetCategories']
        })
    }

    const handleFocus = useCallback(async (idx: any) => {
        const name:any = idx.slice(0, -2)
        const hindi = await handleTranslate(getValues(name))
        setValue(idx, hindi)
    }, [getValues, setValue])

    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Create Category
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <EducationSelect 
                    errors={errors} 
                    label='Select From Class' 
                    name='fromEducationId' 
                    register={register} 
                />
                <EducationSelect 
                    errors={errors} 
                    label='Select To Class' 
                    name='toEducationId' 
                    register={register} 
                />
                <SubjectSelect 
                    errors={errors} 
                    label='Select Subject' 
                    name='subjectId' 
                    register={register} 
                />
                <TextField
                    label="Category"
                    placeholder="Enter category name"
                    customId="name"
                    type="text"
                    register={register}
                    errors={errors?.name?.message}
                />
                <TextField
                    label="Category (In Hindi)"
                    placeholder="Enter category name (in hindi)"
                    customId="nameHi"
                    type="text"
                    onFocus={handleFocus}
                    register={register}
                    errors={errors?.nameHi?.message}
                />
                <Textarea
                    label="Description"
                    placeholder="Enter description"
                    customId="description"
                    register={register}
                    errors={errors?.description?.message}
                />
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default CreateCategory