import React, { useEffect, useRef, useState } from 'react'
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from 'moment';
import { CampaignType } from '../../../service/types';
import AbstractModal from '../../AbstractModal';
import CreateCampaignQuiz from '../../forms/campaign/CreateCampaignQuiz';
import EditCampaign from '../../forms/campaign/EditCampaign';
import { useMutation } from '@apollo/client';
import MarkCompleted from '../../../graphql/mutations/MarkCompleted';
import { notification } from '../../../service';
import { Link } from 'react-router-dom';

interface Props{
  item: CampaignType;
}

const CampaignItem:React.FC<Props> = ({ item }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [quizModal, setQuizModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  
  const popupRef = useRef<HTMLDivElement>(null);
  const [markCompleted] = useMutation(MarkCompleted)

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopup]);

  const handleMarkCompleted = () => {
    markCompleted({
      variables: {
        id: item.id
      },
      onCompleted: (data) => {
        if(data){
          notification({message: 'Campaign marked as completed!'})
        }
      },
      onError: (err) => {
        notification({message: err.message, type: 'danger'})
      },
      refetchQueries: ['GetCampaigns']
    })
  }

  const currentDate = new Date()
  const onGoing = currentDate > new Date(item.startAt) && currentDate < new Date(item.endAt)
  const notStarted = currentDate < new Date(item.startAt)
  const isCompleted = currentDate > new Date(item.endAt)
  
  return (
    <div className='campaign-item position-relative'>
      <span className={`indicator ${onGoing ? 'ongoing':''}`} style={{backgroundColor: isCompleted ? 'red' : notStarted ? 'blue':''}}></span>
      <div className='d-flex justify-content-between'>
        <div style={{width: '90%'}}>
          <h3 style={{fontSize: 18, color: '#b6c8d9'}}>{item.title}</h3>
        </div>
        <span role='button' onClick={togglePopup} style={{ position: 'relative' }}>
          <BsThreeDotsVertical style={{fontSize: 24, color: '#b6c8d9'}} />
          {showPopup && (
            <div className='campaign-popup' ref={popupRef}>
              <span role='button' className='d-block popup-item' onClick={() => setEditModal(true)}>Edit</span>
              {notStarted && <span role='button' className='d-block popup-item' onClick={() => setQuizModal(true)}>Add Quiz</span>}
              <Link to={`/quiz/${item.id}`} style={{textDecoration: 'none', color: '#f5f5f5'}}><span role='button' className='d-block popup-item' onClick={() => console.log("clicked")}>View Quiz</span></Link>
              {item.isCompleted && <span role='button' className='d-block popup-item' onClick={() => console.log("clicked")}>View Leaderboard</span>}
              {!item.isCompleted && <span role='button' className='d-block popup-item' onClick={handleMarkCompleted}>Mark Completed</span>}
            </div>
          )}
        </span>
      </div>
      <div>
        <p style={{color: '#b6c8d9'}}>{item.description}</p>
      </div>
      <div>
        <span style={{color: '#2ce3f6'}} className='d-block'>
          Start Time: {moment(item.startAt).format('DD MMM YYYY hh:mm:ss A')}
        </span>
        <span style={{color: '#2ce3f6'}}>
          End Time: {moment(item.endAt).format('DD MMM YYYY hh:mm:ss A')}
        </span>
      </div>
      <div>
        <span style={{color: '#60e7b8'}} className='d-block'>
          Joined Users: {item._count.participants}
        </span>
        <span style={{color: '#60e7b8'}} className='d-block'>
          Number of Quiz: {item._count.campaignQuiz}
        </span>
        <span style={{color: '#60e7b8'}} className='d-block'>
          Completed: {item.isCompleted.toString()}
        </span>
        <span style={{color: '#60e7b8'}} className='d-block'>
          Entry coins: {item.coins}
        </span>
      </div>
      <AbstractModal
        Component={
          <CreateCampaignQuiz 
            campaignId={item.id}  
            handleClose={() => setQuizModal(false)} 
          />
        } 
        handleClose={() => setQuizModal(false)} 
        show={quizModal} 
      />
      <AbstractModal 
        Component={
          <EditCampaign 
            id={item.id}    
            handleClose={() => setEditModal(false)} 
          />
        } 
        handleClose={() => setEditModal(false)} 
        show={editModal} 
      />
    </div>
  )
}

export default CampaignItem