import { gql } from "@apollo/client";

export default gql`
query GetContent($id: Int!){
  content(id: $id) {
    id
    content
    contentHi
    fileUrl
    list
    title
    titleHi
    subCategory {
      id
    }
    category {
      id
    }
  }
}
`