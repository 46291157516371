import React from "react";
import { themes } from "../../service";

interface Props {
    id: number;
    callback: (id: number) => void;
    message: string;
    onClose: () => void;
    theme: any
}

const CustomAlert: React.FC<Props> = ({ id, callback, message, onClose, theme }) => {

    return (<div className={`${theme} custom-ui`} style={{ backgroundColor: themes[theme].sidebar.backgroundColor }}>
        <h1 style={{ color: themes[theme].sidebar.color }}>Are you sure?</h1>
        <p style={{ color: themes[theme].sidebar.color }}>{message}</p>
        <div className="d-flex justify-content-end align-items-center">
            <button className="no-btn" onClick={onClose}>No</button>
            <button
                onClick={() => {
                    callback(id)
                    onClose();
                }}
            >
                Yes
            </button>
        </div>
    </div>
    );
}

export default CustomAlert;
