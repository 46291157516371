import React from 'react'

import UsersList from '../components/lists/UsersList'

const Users:React.FC = () => {
  return (
    <UsersList />
  )
}

export default Users