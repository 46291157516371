import axios from "axios";

export const handleTranslate = async (text: string) => {
  const API_URL = process.env.REACT_APP_BACKEND_BASE_URL
  try {
    const response = await axios.post(
      `${API_URL}/translate`,
      {
        text,
        targetLanguage: 'hi',
      }
    );
    return response.data?.translatedText;
  } catch (error) {
    console.error("Error translating text:", error);
  }
};