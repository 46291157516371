import React, { useState, useCallback } from 'react'
import AddButton from '../components/common/AddButton'
import AbstractModal from '../components/AbstractModal'
import SubjectFilter from '../components/filters/SubjectFilter'
import CreateCampaign from '../components/forms/campaign/CreateCampaign'
import CampaignList from '../components/modern-lists/campaign/CampaignList'

const Campaigns: React.FC = () => {
  const [show, setShow] = useState(false)
  const [search, setSearch] = useState<string>('')

  const [subjectId, setSubjectId] = useState<number>()

  const handleSubjectSelect = useCallback((v: number) => {
    setSubjectId(v)
  }, [])

  const handleSearch = useCallback((v: string) => {
    setSearch(v)
  }, [])
  return (
    <div style={{ width: "100%", height: '100%' }}>
      <AddButton
        show={show}
        setShow={setShow}
        setSearch={handleSearch}
        filter={<SubjectFilter value={subjectId} setOptionValue={handleSubjectSelect} />}
      />
      <CampaignList search={search} />

      <AbstractModal Component={<CreateCampaign handleClose={() => setShow(false)} />} handleClose={() => setShow(false)} show={show} />
    </div>
  )
}

export default Campaigns