import { gql } from "@apollo/client";

export default gql`
query GetQuiz($quizId: Int!){
  quiz(id: $quizId) {
    id
    description
    descriptionHi
    isActive
    title
    titleHi
    totalQuestions
    category {
      id
      name
    }
    subject {
      id
      name
    }
    subCategory {
      id
      name
    }
  }
}
`