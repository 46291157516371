import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from '../../common/TextField';
import SubmitButton from '../../common/SubmitButtom';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { RootState } from '../../../reducers/store';
import { notification, themes } from '../../../service';
import Textarea from '../../common/Textarea';
import SelectSubCategory from '../../select/SubCategorySelect';
import { useMutation, useQuery } from '@apollo/client';
import SubjectFilter from '../../filters/SubjectFilter';
import GetContent from '../../../graphql/queries/GetContent';
import SelectCategory from '../../select/CategorySelect';
import UpdateContent from '../../../graphql/mutations/UpdateContent';

interface ContentUpdateType {
    title: string
    titleHi: string
    content: string
    contentHi: string
    fileUrl?: string;
    categoryId: number;
    subCategoryId: number;
    list?: string
}

interface Props {
    handleClose: () => void;
    id?: number;
}

const schema = yup
    .object({
        title: yup.string().required("Title is required!"),
        titleHi: yup.string().required("Title (In hindi) is required!"),
        content: yup.string().required("Content is required!"),
        contentHi: yup.string().required("Content (In hindi) is required!"),
        fileUrl: yup.string(),
        list: yup.string(),
        categoryId: yup.number().required("Category is required!"),
        subCategoryId: yup.number().required("Sub Category is required!"),
    })
    .required()

const EditContent: React.FC<Props> = ({ handleClose, id }) => {
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const [categoryId, setCategoryId] = useState<number>()
    const [subCategoryId, setSubCategoryId] = useState<number>()
    const [subjectId, setSubjectId] = useState<number>()
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const [updateContent] = useMutation(UpdateContent)

    const { data, loading, error } = useQuery(GetContent, {
        fetchPolicy: 'network-only',
        variables: {
            id
        }
    })

    useEffect(() => {
        if (!loading && data && data.content) {
            const { title, content, fileUrl, list, subCategory, category, titleHi, contentHi } = data.content;
            setValue('title', title)
            setValue('titleHi', titleHi)
            setValue('content', content)
            setValue('contentHi', contentHi)
            setValue('fileUrl', fileUrl)
            setValue('list', list.join('__'))
            setCategoryId(category.id)
            setSubCategoryId(subCategory.id)
        }
    }, [loading, data, error])
    const onFormSubmit = (v: ContentUpdateType) => {
        const { list } = v;
        const formula = list?.split('__')
        updateContent({
            variables: {
                input: { ...v, id, list: formula }
            },
            onCompleted: (data) => {
                if(data){
                    notification({ message: "Content created successfully!" })
                }
                handleClose()
            },
            onError: (err) => {
                console.log('error');
                notification({ message: err.message, type: 'danger' })
            },
            refetchQueries: ['GetContents']
        })
    }

    const handleSubjectChange = useCallback((v: number) => {
        setSubjectId(v)
    }, [])

    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Edit Content
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <SubjectFilter setOptionValue={handleSubjectChange} value={subjectId} />
                <SelectCategory
                    errors={errors}
                    label='Select Category'
                    name='categoryId'
                    register={register}
                    subjectId={subjectId}
                    value={categoryId?.toString()}
                    setValue={setValue}
                />
                <SelectSubCategory
                    errors={errors}
                    label='Select Sub Category'
                    name='subCategoryId'
                    register={register}
                    categoryId={categoryId}
                    value={subCategoryId?.toString()}
                    setValue={setValue}
                />
                <TextField
                    label="Title"
                    placeholder="Enter title"
                    customId="title"
                    type="text"
                    register={register}
                    errors={errors?.title?.message}
                />
                <TextField
                    label="Title (In Hindi)"
                    placeholder="Enter title (In hindi)"
                    customId="titleHi"
                    type="text"
                    register={register}
                    errors={errors?.titleHi?.message}
                />
                <Textarea
                    label="Description"
                    placeholder="Enter description"
                    customId="content"
                    register={register}
                    errors={errors?.content?.message}
                />
                <Textarea
                    label="Description (In Hindi)"
                    placeholder="Enter description (In hindi)"
                    customId="contentHi"
                    register={register}
                    errors={errors?.contentHi?.message}
                />
                <Textarea
                    label="List"
                    placeholder="Enter list of formula by __ seperated"
                    customId="list"
                    register={register}
                    errors={errors?.list?.message}
                />
                <TextField
                    label="File"
                    placeholder="Enter file url"
                    customId="fileUrl"
                    register={register}
                    errors={errors?.fileUrl?.message}
                />
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default EditContent