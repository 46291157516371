import React, { useState, useCallback, useEffect } from 'react'
import AddButton from '../components/common/AddButton'
import { useQuery } from '@apollo/client'
import GetQuizzes from '../graphql/queries/GetQuizzes'
import { notification } from "../service/index"
import AbstractModal from '../components/AbstractModal'
import CreateQuiz from '../components/forms/quiz/CreateQuiz'
import SubjectFilter from '../components/filters/SubjectFilter'
import CategoryFilter from '../components/filters/CategoryFilter'
import QuizList from '../components/modern-lists/quiz/QuizList'
import Pagination from '@mui/material/Pagination';
import { QuizType } from '../service/types'

const Quizzes: React.FC = () => {
  const [show, setShow] = useState(false)
  const [search, setSearch] = useState<string>('')
  const [categoryId, setCategory] = useState<number>()
  const [subjectId, setSubjectId] = useState<number>()
  const [page, setPage] = useState<number>(1)
  const [quizzes, setQuizzes] = useState<QuizType[]>([])
  const [totalCount, setTotalCount] = useState<number>(0)

  const { error, data, loading, fetchMore } = useQuery(GetQuizzes, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        search,
        categoryId,
        subjectId,
      },
      limit: 18,
      offset: 0
    }
  });

  useEffect(() => {
    if (!loading && data) {
      setQuizzes(data.quizzes?.quizzes)
      setTotalCount(data.quizzes?.totalCount)
    }
    if (error) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, error, data]);

  const handleCategorySelect = useCallback((v: number) => {
    if(v !== 0){
      setCategory(v)
    }else{
      setCategory(undefined)
    }
  }, [])

  const handleSubjectSelect = useCallback((v: number) => {
    if(v !== 0){
      setSubjectId(v)
    }else{
      setSubjectId(undefined)
    }
  }, [])

  const handleSearch = useCallback((v: string) => {
    setSearch(v)
  }, [])

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
    fetchMore({
      variables: {
        offset: (newPage - 1) * 18
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if(fetchMoreResult?.quizzes){
          setQuizzes(fetchMoreResult.quizzes?.quizzes)
        }
      }
    })
  }
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <AddButton
        show={show}
        setShow={setShow}
        setSearch={handleSearch}
        filter={<SubjectFilter value={subjectId} setOptionValue={handleSubjectSelect} />}
        filter2={<CategoryFilter setOptionValue={handleCategorySelect} value={categoryId} subjectId={subjectId} />}
      />
      <div className='my-2'>
        <Pagination count={Math.ceil(totalCount / 18)} page={page} onChange={handleChangePage} />
      </div>
      <QuizList data={quizzes} />
      <AbstractModal Component={<CreateQuiz handleClose={() => setShow(false)} />} handleClose={() => setShow(false)} show={show} />
    </div>
  )
}

export default Quizzes