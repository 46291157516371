import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { appType } from '../service/types';

const initialState:appType = {
    token: null,
    user: null,
    isSystem: false
}

const app = createSlice({
    name: 'app',
    initialState,
    reducers: {
        doLogin: (state, action: PayloadAction<appType>) => {
            state.token = action.payload.token
            state.user = action.payload.user
            state.isSystem = action.payload.user?.id === 1;
        },
        doLogout: (state) => {
            state = initialState
        }
    }
});

export const { doLogin, doLogout } = app.actions;

export default app.reducer;