import { gql } from "@apollo/client";

export default gql`
query GetUsers($where: JSON){
  users(where: $where) {
    id
    firstName
    lastName
    createdAt
    phone
    email
    userType {
      id
      name
    }
    education {
      id
      label
    }
    coins {
      id
      available
      total
      withdrawn
    }
  }
}`