import React, { useEffect, useRef, useState } from 'react'
import { BsThreeDotsVertical } from "react-icons/bs";
import { QuizType } from '../../../service/types';
import AbstractModal from '../../AbstractModal';
import EditQuiz from '../../forms/quiz/EditQuiz';
import CreateQuestions from '../../forms/quiz/CreateQuestions';
import { useMutation } from '@apollo/client';
import EnableQuiz from '../../../graphql/mutations/EnableQuiz';
import { notification } from '../../../service';
import { Link, useParams } from 'react-router-dom';
import RemoveCampaignQuiz from '../../../graphql/mutations/RemoveCampaignQuiz';

interface Props{
  item: QuizType;
  isCampaign?: boolean;
}

const QuizItem:React.FC<Props> = ({ item, isCampaign = false }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [quiestionModal, setQuiestionModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  
  const popupRef = useRef<HTMLDivElement>(null);
  const [enableQuiz] = useMutation(EnableQuiz)
  const { id } = useParams()

  const [removeCampaignQuiz] = useMutation(RemoveCampaignQuiz)

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (showPopup) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopup]);

  const handleActivate = () => {
    enableQuiz({
      variables: {
        quizId: item.id
      },
      onCompleted: (data) => {
        if(data){
          notification({message: `Quiz ${item.isActive ? 'deactivated':'activated'} successfully!`})
        }
      },
      onError: (err) => {
        notification({message: err.message, type: 'danger'})
      },
      refetchQueries: ['GetQuizzes']
    })
  }

  const handleRemove = () => {
    if(!id){
      return notification({message: 'Not allowed!', type: 'warning'})
    }
    removeCampaignQuiz({
      variables: {
        campaignId: Number(id),
        quizId: item.id
      },
      onCompleted: (data) => {
        if(data){
          notification({message: 'Quiz removed successfully!'})
        }
      },
      onError: (err) => {
        notification({message: err.message, type: 'danger'})
      },
      refetchQueries: ['GetCampaignQuiz']
    })
  }

  return (
    <div className='campaign-item'>
      <div className='d-flex justify-content-between'>
        <div style={{width: '90%'}}>
          <h3 style={{fontSize: 18, color: '#b6c8d9'}}>{item.title}</h3>
        </div>
        <span role='button' onClick={togglePopup} style={{ position: 'relative' }}>
          <BsThreeDotsVertical style={{fontSize: 24, color: '#b6c8d9'}} />
          {showPopup && (
            <div className='campaign-popup' ref={popupRef}>
              <span role='button' className='d-block popup-item' onClick={() => setEditModal(true)}>
                Edit
              </span>
              {item.totalQuestions > item._count.questions && <span role='button' className='d-block popup-item' onClick={() => setQuiestionModal(true)}>
                Add Quiestion
              </span>}
              <Link to={`/question/${item.id}`} style={{textDecoration: 'none', color: '#f5f5f5'}}>
                <span className='d-block popup-item'>View Quiestion</span>
              </Link>
              {!isCampaign && <span role='button' className='d-block popup-item' onClick={handleActivate}>
                {item.isActive ? 'Disable': 'Enable'} Quiz
              </span>}
              {isCampaign && <span role='button' className='d-block popup-item' onClick={handleRemove}>
                Remove
              </span>}
            </div>
          )}
        </span>
      </div>
      <div>
        <p style={{color: '#b6c8d9'}}>{item.description}</p>
      </div>
      <div>
        <span style={{color: '#60e7b8'}} className='d-block'>Total Question: {item.totalQuestions}</span>
        <span style={{color: '#60e7b8'}} className='d-block'>Number of Question: {item._count.questions}</span>
        <span style={{color: '#60e7b8'}} className='d-block'>Active: {item.isActive.toString()}</span>
        <span style={{color: '#60e7b8'}} className='d-block'>Personal: {item?.isPersonal?.toString()}</span>
      </div>
      <AbstractModal
        Component={
          <CreateQuestions
            questions={item._count.questions}
            total={item.totalQuestions}
            quizId={item.id}
            handleClose={() => setQuiestionModal(false)} 
          />
        } 
        handleClose={() => setQuiestionModal(false)} 
        show={quiestionModal} 
      />
      <AbstractModal 
        Component={
          <EditQuiz 
            id={item.id}    
            handleClose={() => setEditModal(false)} 
          />
        } 
        handleClose={() => setEditModal(false)} 
        show={editModal} 
      />
    </div>
  )
}

export default QuizItem