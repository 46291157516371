import React, { useState, useEffect } from 'react'
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { notification, themes } from '../../service'
import GetCategories from '../../graphql/queries/GetCategories'
import { useQuery } from '@apollo/client'
import { CategoryType, Option } from '../../service/types'
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/store';

interface Props{
    setOptionValue: (v: number) => void;
    value?: number;
    subjectId?: number
}

const CategoryFilter: React.FC<Props> = ({setOptionValue, value, subjectId}) => {
    const [categories, setCategories] = useState<Option[]>([])
    const { theme } = useSelector((state:RootState) => state.sidebar)
    const { data, loading, error } = useQuery(GetCategories, {
        fetchPolicy: 'network-only',
        variables: {
            where: { subjectId: subjectId === 0 ? undefined : subjectId }
        }
    })
    
    useEffect(() => {
        if (!loading && data && data.categories) {
          const formatedData = data.categories.map((item: CategoryType) => ({ value: item.id, label: `${item.name} ${item.fromEducationId} - ${item.toEducationId}` }))
          formatedData.unshift({value: 0, label: "Select Category"})
          setCategories(formatedData)
        }
        if (error) {
            notification({ message: error.message, type: "danger" });
        }
    }, [loading, error, data])
    return (
        <FormControl style={{width: "100%", marginTop: 15}} variant="standard">
        <InputLabel
          id="demo-simple-select-label"
          style={{ fontWeight: "bold", color: themes[theme].sidebar.color }}>
          Select Category
        </InputLabel>
        <Select label="Select Category" style={{color: themes[theme].sidebar.color}} value={value} onChange={(e: any)=> setOptionValue(e.target.value)}>
          {categories.length > 0 ? (
            categories.map((item:Option, key: number) => (
              <MenuItem key={key} value={item.value}>
                {item.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem value={undefined}>Select Category</MenuItem>
          )}
        </Select>
        {/* <SelectSearch
          label='Select Category' 
          options={categories} 
          selectedValue={value}
          setSelectedValue={setOptionValue} 
        /> */}
      </FormControl>
    )
}

export default CategoryFilter