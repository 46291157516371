import { gql } from "@apollo/client";

export default gql`
query GetCampaignQuiz($id: Int!){
  campaignQuiz(id: $id) {
    id
    title
    titleHi
    totalQuestions
    description
    descriptionHi
    isActive
    isPersonal
    _count {
      questions
    }
    category {
      id
      name
    }
    subject {
      id
      name
    }
  }
}
`