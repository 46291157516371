import React, { useCallback, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from '../common/TextField';
import SubmitButton from '../common/SubmitButtom';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { RootState } from '../../reducers/store';
import { notification, themes } from '../../service';
import { useMutation } from '@apollo/client';
import SubjectFilter from '../filters/SubjectFilter';
import Textarea from '../common/Textarea';
import CategoryFilter from '../filters/CategoryFilter';
import SelectSubCategory from '../select/SubCategorySelect';
import Topic from '../../graphql/mutations/Topic';

interface TopicCreateType {
    title: string;
    description: string;
    categoryId: number;
    subCategoryId: number;
}

interface Props{
    handleClose: () => void;
}

const schema = yup
    .object({
        title: yup.string().required("Title is required!"),
        description: yup.string().required("Description is required!"),
        categoryId: yup.number().required("Category is required!"),
        subCategoryId: yup.number().required("Sub Category is required!")
    })
    .required()

const CreateTopic: React.FC<Props> = ({handleClose}) => {
    const [subjectId, setSubjectId] = useState<number>()
    const [categoryId, setCategoryId] = useState<number>()
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const [createTopic] = useMutation(Topic)

    const onFormSubmit = (v: TopicCreateType) => {
        createTopic({
            variables: {
                input: v
            },
            onCompleted: (data) => {
                console.log('data', data);
                handleClose()
            },
            onError: (err) => {
                console.log('error');
                notification({message: err.message, type: 'danger'})
            },
            refetchQueries: ['GetTopics']
        })
    }
    const handleSubjectSelect = useCallback((v:number) => {
        setSubjectId(v)
    },[])
    const handleCategorySelect = useCallback((v:number) => {
        setValue('categoryId', v)
        setCategoryId(v)
    },[setValue])
    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Create Topics
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <SubjectFilter value={subjectId} setOptionValue={handleSubjectSelect} />
                <CategoryFilter value={categoryId} setOptionValue={handleCategorySelect} subjectId={subjectId} />
                <SelectSubCategory
                    errors={errors}
                    label='Select Sub Category'
                    name='subCategoryId'
                    register={register}
                    categoryId={categoryId}
                />
                <TextField
                    label="Title"
                    placeholder="Enter title"
                    customId="title"
                    type="text"
                    register={register}
                    errors={errors?.title?.message}
                />
                <Textarea
                    label="Description"
                    placeholder="Enter description"
                    customId="description"
                    register={register}
                    errors={errors?.description?.message}
                />
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default CreateTopic