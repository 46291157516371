import React, { useEffect, useState } from 'react'
import SelectField from '../common/SelectField'
import { useQuery } from '@apollo/client'
import { notification } from '../../service'
import type { SelectType, CategoryType } from '../../service/types'
import GetSubjects from '../../graphql/queries/GetSubjects'

interface Props {
    errors: any;
    register: any;
    name: string;
    label: string;
    value?: string;
    setValue?: (x: any, y: any) => void;
}

const SubjectSelect: React.FC<Props> = ({errors, label, name, register, setValue, value}) => {
    const [subjects, setSubjects] = useState<SelectType[]>([])

    const { data, loading, error } = useQuery(GetSubjects, {
        fetchPolicy: 'network-only',
        variables: {}
    })

    useEffect(() => {
        if (!loading && data && data.subjects) {
            const formatedData = data.subjects.map((item: CategoryType) => ({ value: item.id, label: item.name }))
            setSubjects(formatedData)
        }
        if (error) {
            notification({ message: error.message, type: "danger" });
        }
    }, [loading, error, data])
    return (
        <SelectField
            data={subjects}
            errors={errors}
            label={label}
            name={name}
            register={register}
            setValue={setValue}
            value={value}
        />
    )
}

export default SubjectSelect