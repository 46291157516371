import React, { useEffect, useState } from 'react'
import SelectField from '../common/SelectField'
import { useQuery } from '@apollo/client'
import { notification } from '../../service'
import type { SelectType } from '../../service/types'
import GetEducation from '../../graphql/queries/GetEducation'

interface Props {
    errors: any;
    register: any;
    name: string;
    label: string;
    value?: string;
    setValue?: (x: any, y: any) => void;
}

const EducationSelect:React.FC<Props> = ({errors, label, name, register, setValue, value}) => {
    const [education, setEducation] = useState<SelectType[]>([])

    const { data, loading, error } = useQuery(GetEducation, {
        fetchPolicy: 'network-only',
        variables: {where: {}}
    })

    useEffect(() => {
        if (!loading && data && data.getEducation) {
            const formatedData = data.getEducation.map((item: any) => ({ value: item.id, label: item.label }))
            setEducation(formatedData)
        }
        if (error) {
            notification({ message: error.message, type: "danger" });
        }
    }, [loading, error, data])
    return (
        <SelectField
            data={education}
            errors={errors}
            label={label}
            name={name}
            register={register}
            setValue={setValue}
            value={value}
        />
    )
}

export default EducationSelect