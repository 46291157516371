import React, { useEffect } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from '../../common/TextField';
import SubmitButton from '../../common/SubmitButtom';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { RootState } from '../../../reducers/store';
import { notification, themes } from '../../../service';
import Textarea from '../../common/Textarea';
import NumberField from '../../common/NumberField';
import { useMutation, useQuery } from '@apollo/client';
import UpdateQuiz from '../../../graphql/mutations/UpdateQuiz';
import GetQuiz from '../../../graphql/queries/GetQuiz';

interface QuizEditType {
    title: string;
    titleHi: string;
    description: string;
    descriptionHi: string;
    totalQuestions: number;
    categoryId: number;
    subCatId?: number | null;
    subjectId: number;
}

interface Props {
    handleClose: () => void;
    id: number;
}

const schema = yup
    .object({
        title: yup.string().required("Title is required!"),
        titleHi: yup.string().required("Hindi title is required!"),
        description: yup.string().required("Description is required!"),
        descriptionHi: yup.string().required("Hindi description is required!"),
        totalQuestions: yup.number().required("Total no. of questions is required!"),
        subjectId: yup.number().required("Subject is required!"),
        categoryId: yup.number().required("Category is required!"),
        subCatId: yup.number().nullable()
    })
    .required()

const EditQuiz: React.FC<Props> = ({ handleClose, id }) => {
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const [updateQuiz] = useMutation(UpdateQuiz)

    const {data, loading, error} = useQuery(GetQuiz, {
        fetchPolicy: 'network-only',
        variables: {
            quizId: id
        }
    })

    useEffect(() => {
        if(!loading && data && data.quiz){
            const {category, subCategory, subject, title, titleHi, 
                description, descriptionHi, totalQuestions} = data.quiz
            setValue('title', title)
            setValue('titleHi', titleHi)
            setValue('description', description)
            setValue('descriptionHi', descriptionHi)
            setValue('totalQuestions', totalQuestions)
            setValue('categoryId', category.id)
            setValue('subCatId', subCategory?.id)
            setValue('subjectId', subject.id)
        }
        if(error){
            notification({message: error.message, type: "danger"})
        }
    }, [loading, error, data, setValue])

    const onFormSubmit = (v: QuizEditType) => {
        updateQuiz({
            variables: {
                input: {...v, id}
            },
            onCompleted: (data) => {
                console.log('data', data);
                handleClose()
            },
            onError: (err) => {
                console.log('error');
                notification({ message: err.message, type: 'danger' })
            },
            refetchQueries: ['GetQuizzes']
        })
    }

    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Edit Quiz
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <TextField
                    label="Title"
                    placeholder="Enter title"
                    customId="title"
                    type="text"
                    register={register}
                    errors={errors?.title?.message}
                />
                <TextField
                    label="Title (In hindi)"
                    placeholder="Enter title (in hindi)"
                    customId="titleHi"
                    type="text"
                    register={register}
                    errors={errors?.titleHi?.message}
                />
                <Textarea
                    label="Description"
                    placeholder="Enter description"
                    customId="description"
                    register={register}
                    errors={errors?.description?.message}
                />
                <Textarea
                    label="Description (In hindi)"
                    placeholder="Enter description in hindi"
                    customId="descriptionHi"
                    register={register}
                    errors={errors?.descriptionHi?.message}
                />
                <NumberField
                    label="Total number of questions"
                    placeholder="Enter number of questions"
                    customId="totalQuestions"
                    register={register}
                    errors={errors?.totalQuestions?.message}
                />
                {/* <SubjectFilter
                    setOptionValue={handleSubject}
                    value={subjectId}
                />
                <CategoryFilter
                    setOptionValue={handleCategory}
                    value={categoryId}
                    subjectId={subjectId}
                />
                <SelectSubCategory
                    categoryId={categoryId}
                    disabled={!categoryId}
                    errors={errors}
                    label='Select Sub Category'
                    name='subCatId'
                    register={register}
                /> */}
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default EditQuiz