import React from 'react'
import { BlockMath } from 'react-katex';
// import 'katex/dist/katex.min.css';

interface Props{
  children: string;
}

const LatexWrapper:React.FC<Props> = ({children}) => {
  return (
    <BlockMath>{children}</BlockMath>
  )
}

export default LatexWrapper