import { configureStore } from '@reduxjs/toolkit';

import app from './app';
import sidebar from './sidebar';
import modal from './modal';

export const store = configureStore({
  reducer: {
    app,
    sidebar,
    modal
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch