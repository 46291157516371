import React, { useState, useEffect } from 'react'
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { notification, themes } from '../../service'
import { useQuery } from '@apollo/client'
import { SelectType } from '../../service/types'
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/store';
import GetFilterQuizzes from '../../graphql/queries/GetFilterQuizzes';

interface Props{
    setOptionValue: (v: number) => void;
    value?: number;
}

const QuizFilter: React.FC<Props> = ({setOptionValue, value}) => {
    const [quizzes, setQuizzes] = useState<SelectType[]>([])
    const { theme } = useSelector((state:RootState) => state.sidebar)
    const { data, loading, error } = useQuery(GetFilterQuizzes, {
        fetchPolicy: 'network-only',
        variables: {
            where: {}
        }
    })

    useEffect(() => {
        if (!loading && data && data.filterQuizzes) {
            const formatedData = data.filterQuizzes.map((item: any) => ({ value: item.id, label: item.title }))
            setQuizzes(formatedData)
        }
        if (error) {
            notification({ message: error.message, type: "danger" });
        }
    }, [loading, error, data])
    return (
        <FormControl style={{width: "100%"}} variant="standard">
        <InputLabel
          id="demo-simple-select-label"
          style={{ fontWeight: "bold", color: themes[theme].sidebar.color }}>
          Select Quiz
        </InputLabel>
        <Select label="Select Quiz" value={value} onChange={(e: any)=> setOptionValue(e.target.value)}>
          {quizzes.length > 0 ? (
            quizzes.map((item:SelectType, key: number) => (
              <MenuItem key={key} value={item.value}>
                {item.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">Select Quiz</MenuItem>
          )}
        </Select>
      </FormControl>
    )
}

export default QuizFilter