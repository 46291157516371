import { gql } from "@apollo/client";

export default gql`
query GetCampaign($campaignId: Int!){
  campaign(id: $campaignId) {
    id
    coins
    description
    descriptionHi
    startAt
    endAt
    title
    titleHi
    campaignQuiz {
      quizId
    }
    participants {
      userId
    }
    _count {
      participants
      campaignQuiz
    }
  }
}
`