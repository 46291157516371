import { gql } from "@apollo/client";

export default gql`
query GetSubject($subjectId: Int!){
  subject(id: $subjectId) {
    id
    name
    nameHi
  }
}
`