import React from 'react'
import LatexWrapper from '../components/common/LatexWrapper'

const FeedBack:React.FC = () => {
  const latexTable = `
\\textbf{Types of Numbers and their Properties:} \\\\

\\begin{array}{|c|c|c|c|c|}
\\hline
\\textbf{Type} & \\textbf{Symbol} & \\textbf{Example} & \\textbf{Includes} & \\textbf{Operations Closed Under} \\\\
\\hline
\\text{Natural Numbers} & \\mathbb{N} & 1, 2, 3, 4 & \\text{Positive integers} & \\text{Addition, Multiplication} \\\\
\\hline
\\text{Whole Numbers} & \\mathbb{N}_0 & 0, 1, 2, 3 & \\text{Natural numbers + 0} & \\text{Addition, Multiplication} \\\\
\\hline
\\text{Integers} & \\mathbb{Z} & -2, -1, 0, 1, 2 & \\text{Whole numbers + negatives} & \\text{Addition, Subtraction, Multiplication} \\\\
\\hline
\\text{Rational Numbers} & \\mathbb{Q} & \\frac{1}{2}, \\frac{3}{4}, -5 & \\text{Integers + fractions} & \\text{Addition, Subtraction, Multiplication, Division} \\\\
\\hline
\\text{Irrational Numbers} & & \\sqrt{2}, \\pi, e & \\text{Non-repeating, non-terminating decimals} & \\text{None (no closure)} \\\\
\\hline
\\text{Real Numbers} & \\mathbb{R} & -2.5, 0, \\pi, \\sqrt{2} & \\text{Rational + Irrational} & \\text{Addition, Subtraction, Multiplication, Division} \\\\
\\hline
\\text{Complex Numbers} & \\mathbb{C} & 3 + 2i, -1 + 4i & \\text{Real + Imaginary numbers} & \\text{Addition, Subtraction, Multiplication, Division} \\\\
\\hline
\\end{array}
`;

  return (
    <div style={{color: '#ccc'}}>
      <LatexWrapper>
        {/* {'$\\frac{1}{2}, \\frac{3}{4}, -5$'} */}
        {latexTable}
      </LatexWrapper>
    </div>
  )
}

export default FeedBack