import React, { useState, useEffect } from 'react'
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { notification, themes } from '../../service'
import { useQuery } from '@apollo/client'
import { Option, CategoryType } from '../../service/types'
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers/store';
import GetSubjects from '../../graphql/queries/GetSubjects';

interface Props {
  setOptionValue: (v: number) => void;
  value?: number;
}

const SubjectFilter: React.FC<Props> = ({ setOptionValue, value }) => {
  const [subjects, setSubjects] = useState<Option[]>([])
  const { theme } = useSelector((state: RootState) => state.sidebar)
  const { data, loading, error } = useQuery(GetSubjects, {
    fetchPolicy: 'network-only',
    variables: {}
  })

  useEffect(() => {
    if (!loading && data && data.subjects) {
      const formatedData = data.subjects.map((item: CategoryType) => ({ value: item.id, label: item.name }))
      setSubjects(formatedData)
    }
    if (error) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, error, data])
  return (
    <FormControl style={{ width: "100%" }} variant="standard">
      <InputLabel
          id="demo-simple-select-label"
          style={{ fontWeight: "bold", color: themes[theme].sidebar.color }}>
          Select Subject
        </InputLabel>
      <Select label="Select Subject" style={{color: themes[theme].sidebar.color}} value={value} onChange={(e: any)=> setOptionValue(e.target.value)}>
          <MenuItem value={0}>Select Subject</MenuItem>
          {
            subjects.map((item:Option, key: number) => (
              <MenuItem key={key} value={item.value}>
                {item.label}
              </MenuItem>
            ))
          }
        </Select>
      {/* <SelectSearch
        label='Select Subject'
        options={subjects}
        selectedValue={value}
        setSelectedValue={setOptionValue}
      /> */}
    </FormControl>
  )
}

export default SubjectFilter