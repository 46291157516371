import React from "react";

interface Props{
    placeholder: String
    label: String
    customId: string
    register: any
    errors: String | undefined
    min?: number;
    max?: number
}

const NumberField:React.FC<Props> = ({ placeholder, label, customId, register, errors, min, max }) => {
    return (
        <div style={{marginBottom: 14}}>
            <label htmlFor={customId} className="text-label" style={{display: 'block', color: '#1346a3',fontWeight:"bold"}}>
                {label}
            </label>
            <input placeholder={placeholder} id={customId} type="number" min={min || 10} max={max || 30} step={5} {...register(customId)} className="text-field" />
            {errors && <p style={{color: 'red'}}>{errors}</p>}
        </div>
    )
}
export default NumberField;