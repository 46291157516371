import { gql } from "@apollo/client";

export default gql`
query GetTopics($where: JSON){
  topics(where: $where) {
    id
    description
    title
    categoryId
    subCategoryId
  }
}
`