import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { notification } from "../service/index"
import QuizList from '../components/modern-lists/quiz/QuizList'
import GetCampaignQuiz from '../graphql/queries/GetCampaignQuiz'
import { useParams } from 'react-router-dom'

const Quiz:React.FC = () => {
  const { id } = useParams()

  const { error, data, loading } = useQuery(GetCampaignQuiz, {
    fetchPolicy: "network-only",
    variables: {
      id: Number(id)
    }
  });

  useEffect(() => {
    if (!loading && error && error.message) {
      notification({ message: error.message, type: "danger" });
    }
  }, [loading, error]);


  return (
    <div style={{ width: "100%", position: "relative" }}>
      <QuizList data={loading ? [] : data.campaignQuiz} isCampaign />
    </div>
  )
}

export default Quiz