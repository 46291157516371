import React, { useCallback, useState } from 'react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextField from '../../common/TextField';
import SubmitButton from '../../common/SubmitButtom';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux'
import { RootState } from '../../../reducers/store';
import { notification, themes } from '../../../service';
import Textarea from '../../common/Textarea';
import NumberField from '../../common/NumberField';
import SelectSubCategory from '../../select/SubCategorySelect';
import { useMutation } from '@apollo/client';
import Quiz from '../../../graphql/mutations/Quiz';
import SubjectFilter from '../../filters/SubjectFilter';
import CategoryFilter from '../../filters/CategoryFilter';
import { handleTranslate } from '../../../service/translate';

interface QuizCreateType {
    title: string
    titleHi: string
    description: string
    descriptionHi: string
    totalQuestions: number;
    categoryId: number;
    subCatId?: number | null;
    subjectId: number;
}

interface Props{
    handleClose: () => void;
}

const schema = yup
    .object({
        title: yup.string().required("Title is required!"),
        titleHi: yup.string().required("Title (In hindi) is required!"),
        description: yup.string().required("Description is required!"),
        descriptionHi: yup.string().required("Description (In hindi) is required!"),
        totalQuestions: yup.number().required("Total no. of questions is required!"),
        subjectId: yup.number().required("Subject is required!"),
        categoryId: yup.number().required("Category is required!"),
        subCatId: yup.number().nullable()
    })
    .required()

const CreateQuiz: React.FC<Props> = ({handleClose}) => {
    const [subjectId, setSubjectId] = useState<number>()
    const [categoryId, setCategoryId] = useState<number>()
    const { theme } = useSelector((state: RootState) => state.sidebar)
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const [createQuiz] = useMutation(Quiz)

    const onFormSubmit = (v: QuizCreateType) => {
        createQuiz({
            variables: {
                input: v
            },
            onCompleted: (data) => {
                console.log('data', data);
                handleClose()
            },
            onError: (err) => {
                console.log('error');
                notification({message: err.message, type: 'danger'})
            },
            refetchQueries: ['GetQuizzes']
        })
    }

    const handleSubject = useCallback((id: number) => {
        setSubjectId(id)
        setValue('subjectId', id)
    }, [setValue])

    const handleCategory = useCallback((id: number) => {
        setCategoryId(id)
        setValue('categoryId', id)
    }, [setValue])

    const handleFocus = useCallback(async (idx: any) => {
        const name:any = idx.slice(0, -2)
        const hindi = await handleTranslate(getValues(name))
        setValue(idx, hindi)
    }, [getValues, setValue])

    return (
        <div>
            <Typography variant="h4" style={{ marginBottom: 10, textAlign: 'center', color: themes[theme].sidebar.color }}>
                Create Quiz
            </Typography>
            <form className={`form-${theme} py-3`} onSubmit={handleSubmit(onFormSubmit)}>
                <TextField
                    label="Title"
                    placeholder="Enter title"
                    customId="title"
                    type="text"
                    register={register}
                    errors={errors?.title?.message}
                />
                <TextField
                    label="Title (In Hindi)"
                    placeholder="Enter title (In hindi)"
                    customId="titleHi"
                    onFocus={handleFocus}
                    type="text"
                    register={register}
                    errors={errors?.titleHi?.message}
                />
                <Textarea
                    label="Description"
                    placeholder="Enter description"
                    customId="description"
                    register={register}
                    errors={errors?.description?.message}
                />
                <Textarea
                    label="Description (In Hindi)"
                    placeholder="Enter description (In hindi)"
                    customId="descriptionHi"
                    onFocus={handleFocus}
                    register={register}
                    errors={errors?.descriptionHi?.message}
                />
                <NumberField
                    label="Total number of questions"
                    placeholder="Enter number of questions"
                    customId="totalQuestions"
                    register={register}
                    errors={errors?.totalQuestions?.message}
                />
                <SubjectFilter 
                    setOptionValue={handleSubject} 
                    value={subjectId} 
                />
                <CategoryFilter 
                    setOptionValue={handleCategory} 
                    value={categoryId}
                    subjectId={subjectId}
                />
                <SelectSubCategory
                    categoryId={categoryId}
                    disabled={!categoryId}
                    errors={errors}
                    label='Select Sub Category'
                    name='subCatId'
                    register={register}
                />
                <SubmitButton label="Submit" />
            </form>
        </div>
    )
}

export default CreateQuiz