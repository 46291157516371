import React, { useState, useCallback } from 'react'

import AbstractModal from '../components/AbstractModal'
import AddButton from '../components/common/AddButton'
import CreateContent from '../components/forms/content/CreateContent'
import SubCategoryFilter from '../components/filters/SubCategoryFilter'
import ContentList from '../components/modern-lists/content/ContentList'

const Contents: React.FC = () => {
  const [show, setShow] = useState(false)
  const [subCatId, setSubCatId] = useState<number>()
  const [search, setSearch] = useState<string>('')

  const handleSubCatChange = useCallback((v: number) => {
    setSubCatId(v)
  }, [])

  const handleSearch = useCallback((v: string) => {
    setSearch(v)
  }, [])

  const handleShow = useCallback((v: boolean) => {
    setShow((prev: boolean) => !prev)
  }, [])

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <AddButton
        setSearch={handleSearch}
        show={show}
        setShow={handleShow}
        filter={<SubCategoryFilter value={subCatId} setOptionValue={handleSubCatChange} />}
      />
      <ContentList search={search} subCatId={subCatId} />
      <AbstractModal
        Component={<CreateContent handleClose={() => setShow(false)} />}
        handleClose={() => setShow(false)}
        show={show}
      />
    </div>
  )
}

export default Contents