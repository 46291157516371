import { gql } from "@apollo/client";

export default gql`
query GetCategories($where: JSON){
  categories(where: $where) {
    id
    name
    nameHi
    description
    fromEducationId
    toEducationId
  }
}

`