import React, { useEffect, useState } from 'react'
import SelectField from '../common/SelectField'
import { useQuery } from '@apollo/client'
import GetCategories from '../../graphql/queries/GetCategories'
import { notification } from '../../service'
import type { SelectType, CategoryType } from '../../service/types'

interface Props {
    errors: any;
    register: any;
    name: string;
    label: string;
    value?: string;
    setValue?: (x: any, y: any) => void;
    subjectId?: number;
}

const SelectCategory:React.FC<Props> = ({errors, label, name, register, setValue, value, subjectId}) => {
    const [categories, setCategories] = useState<SelectType[]>([])

    const { data, loading, error } = useQuery(GetCategories, {
        fetchPolicy: 'network-only',
        variables: {
            where: {subjectId: subjectId === 0 ? undefined : subjectId}
        }
    })

    useEffect(() => {
        if (!loading && data && data.categories) {
            const formatedData = data.categories.map((item: CategoryType) => ({ value: item.id, label: `${item.name} ${item.fromEducationId} - ${item.toEducationId}` }))
            setCategories(formatedData)
        }
        if (error) {
            notification({ message: error.message, type: "danger" });
        }
    }, [loading, error, data])
    return (
        <SelectField
            data={categories}
            errors={errors}
            label={label}
            name={name}
            register={register}
            setValue={setValue}
            value={value}
        />
    )
}

export default SelectCategory